import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Paper } from '../Paper';
import { Grid } from '../Grid';
import { Input } from '../Input';
import { useFormik } from 'formik';
import { CustomerSignInValidationSchema } from '@next-pontos/validations';
import CustomerAuthContext from '../../context/customerAuth';
import { Person } from '@mui/icons-material';

const pages = ['Home'];
const settings = ['Sair'];

export interface CustomerLayoutProps {
  children: React.ReactNode
}

export const CustomerLayout = ({children}: CustomerLayoutProps) => {
  const {signIn, me, signOut} = React.useContext(CustomerAuthContext)
  
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    signOut(() => window.location.reload())
  }

  const formik = useFormik({
    initialValues: {
        email: '',
        password: '',
    },
    validationSchema: CustomerSignInValidationSchema,
    onSubmit: async (values) => {
        await signIn(values)
    },
    validateOnMount: true
})


  if (!me?.id) {
    return (
      <Grid container justifyContent="center" minHeight={"100vh"} alignItems="center" style={{
        backgroundColor: 'white',
        backgroundImage: 'url(/background.png)',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    }}>
        <Grid item xs={4}>
          <Paper elevation={1}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container padding={2} spacing={2} justifyContent="center" textAlign={"center"}>
                  <img src="/logo.png" alt="Logo" style={{width: '50%'}}/>
                  <Grid item xs={12}> 
                      <Input 
                          label="Email"
                          name="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          helperText={formik.touched.email && formik.errors.email}
                          error={!!formik.touched.email && !!formik.errors.email}
                      />
                  </Grid>
                  <Grid item xs={12}>
                      <Input 
                          label="Senha"
                          type="password"
                          name="password"
                          onChange={formik.handleChange}
                          helperText={formik.touched.password && formik.errors.password}
                          onBlur={formik.handleBlur}
                          error={!!formik.touched.password && !!formik.errors.password}
                      />
                  </Grid>
                  <Grid item marginTop={2} xs={12}>
                      <Button disabled={!formik.isValid} type="submit" variant='contained'>Entrar</Button>
                  </Grid>
              </Grid>
          </form>
        </Paper>
        </Grid>
      </Grid>
    )
  }

  return (
    <React.Fragment>
      <AppBar position="static" style={{backgroundColor: 'white'}}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <img src="./logo.png" width={90}/>
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }} />
            <Box sx={{ flexGrow: 0 }}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={me.name}>
                  <Person />
                </Avatar>
              </IconButton>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleLogout}>
                    <Typography sx={{ textAlign: 'center' }}>{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {children}
    </React.Fragment>
  );
}