import { Button, Grid, Menu, Layout, Paper, Table, Alert, ButtonGroup, Loader } from "../../components";
import useSWR from 'swr'
import API from "../../API";
import moment from "moment";
import { COMPANY_OPTIONS } from "../../constants";
import currency from 'currency.js'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useAPI, useAxios } from '../../hooks'
import { useContext } from "react";
import AuthContext from "../../context/auth";
import { AlertTitle, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getBidStatusColor, getBidStatusLabel } from "../../utils";
import { APP_CONTACTS, APP_NAME } from "@next-pontos/settings";

export function MyBids () {
    const { me } = useContext(AuthContext)

    const navigate = useNavigate()

    const [bids, refetchBids] = useAxios(`/bid?user_id=${me?.id.toString()}`)

    const api = useAPI()
    
    return (
        <Layout>
            <Grid container justifyContent="center" minHeight={"calc(100vh - 64px)"} padding={3} overflow="scroll">
                {bids.loading ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) : (<Grid item xs={12} sm={10}>
                    <Grid container paddingBottom={2} justifyContent="flex-start">
                        <Grid item xs={12} justifyContent="flex-start">
                            <Alert icon={false} color="warning">
                                <AlertTitle>Atenção!</AlertTitle>
                                <span>Durante o processo de emissão de passagens, nosso time pode entrar em contato solicitando o token da transação de Smiles ou Latam.</span><br />
                                <span>O token de transação é aquela sequência de números que são enviados por e-mail ou SMS para confirmar o resgate.</span><br />
                                <span>Para tornar tudo muito seguro, nossas ligações serão feitas <strong>EXCLUSIVAMENTE</strong> a partir do número <strong>+55 (11) 5200-1650</strong>, portanto, sugerimos que cadastre na agenda do seu celular como {APP_NAME}.</span><br />
                                <span>Eventualmente nossos contatos também são feitos pelo <strong>WhatsApp Oficial {`+${APP_CONTACTS.whatsapp.slice(0, 2)} ${APP_CONTACTS.whatsapp.slice(2, 4)} ${APP_CONTACTS.whatsapp.slice(4, 9)}-${APP_CONTACTS.whatsapp.slice(9, 13)}`}</strong> ou o <strong>Telegram {`@${APP_CONTACTS.telegram}`}</strong>.</span><br />
                            </Alert>
                        </Grid>
                    </Grid>
                    <Grid container paddingBottom={2} justifyContent="flex-start">
                        {bids?.data?.find((item: {status: string}) => item.status === 'ACCEPTED') && (<Grid item xs={12} justifyContent="flex-start">
                            <Alert icon={false}>
                                <strong>Você possui ofertas aceitas!</strong>
                                <br />
                                Insira os dados da conta para a emissão clicando no menu ao final da linha.
                            </Alert>
                        </Grid>)}
                    </Grid>
                    <Grid container paddingBottom={2} justifyContent="flex-start">
                        <Grid item xs={4} justifyContent="flex-start">
                            <Button href="/minhas-ofertas/nova">
                                Nova Oferta
                            </Button>
                        </Grid>
                    </Grid>
                    <Paper elevation={1}>
                    <Table 
                        columns={[
                            { field: 'id', headerName: 'ID', width: 100 },
                            { field: 'created_at', headerName: 'Data', flex: 1, valueFormatter: ({value}) => {
                                return moment(value).format('DD/MM/YYYY HH:mm:ss')
                            } },
                            { field: 'company', headerName: 'Programa', flex: 1 },
                            { field: 'amount', headerName: 'Quantidade', flex: 1, valueFormatter: ({value}) => {
                                return Number(value).toLocaleString('pt-BR')
                            }},
                            { field: 'price_per_k', headerName: 'Valor do Milheiro', flex: 1, valueFormatter: ({value}) => {
                                return currency(value).format({
                                    symbol: 'R$ ',
                                    decimal: ',',
                                    separator: '.'
                                })
                            }},
                            { field: 'pax', headerName: 'CPFs Disponíveis', flex: 1, 
                            valueFormatter: ({value}) => {
                                return value === null ? '∞' : Number(value)
                            }},
                            {
                                field: 'status', headerName: 'Status', flex: 1, 
                                valueGetter: ({value}) => {
                                    return getBidStatusLabel(value)
                                },
                                renderCell: ({ formattedValue, row }) => {
                                    return (
                                        <Chip color={getBidStatusColor(row.status)} label={formattedValue} />
                                    )
                                }
                            },
                            {
                                field: 'actions', headerName: '', sortable: false, filterable: false, disableColumnMenu: true, renderCell: ({row}) => {
                                    const menuOptions = [
                                        { 
                                            label: 'Excluir Oferta', 
                                            onClick: async () => {
                                                await api.delete(`/bid/${row.id}`)
                                                refetchBids()
                                            }
                                        }
                                    ]

                                    if (row.status === 'ACCEPTED' || row.status === 'AWAITING_ORDER') {
                                        menuOptions.push({ 
                                            label: 'Selecionar Conta', 
                                            onClick: async () => {
                                                navigate(`/minhas-ofertas/${row.id}/selecionar-conta`)
                                            }
                                        })
                                    }

                                    return (
                                        <ButtonGroup>
                                            <Menu
                                                disabled={['ON_ORDER', 'DONE'].includes(row.status)}
                                                options={menuOptions}
                                            >
                                                <MoreHorizIcon />
                                            </Menu>
                                        </ButtonGroup>
                                    )
                                }
                            }
                        ]}
                        rows={bids?.data?.map((item: any) => ({
                            id: item.id,
                            created_at: item.created_at,
                            company: COMPANY_OPTIONS.find(cia => cia.value === item.company)?.label,
                            amount: item.amount,
                            price_per_k: item.price_per_k,
                            pax: item.pax ? Number(item.pax) : null,
                            status: item.status
                        })) || []}
                    />
                    </Paper>
                </Grid>)}
            </Grid>
        </Layout>
    )
}