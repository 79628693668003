import { Alert, Grid, Layout, Loader, Paper, Typography } from "../../components";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid, PieChart, Pie, Cell, BarChart, Bar } from 'recharts';
import { Palette, PaletteColor, useTheme } from "@mui/material";
import moment from "moment";
import { capitalizeFirstLetter, formatMoney, getBidStatusColor, getBidStatusLabel } from "../../utils";
import { COMPANY_OPTIONS } from "../../constants";
import { Fragment } from "react";
import { Link } from "@mui/material";
import currency from "currency.js";
import {useAxios} from "../../hooks";

export function Home () { 
    const theme = useTheme()
    
    const [dashboard] = useAxios('/user/dashboard')
    
    return (
        <Layout>
            <Grid container justifyContent="center" height={"calc(100vh - 64px)"} padding={3} spacing={2} overflow="scroll">
                {dashboard.loading ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) : (<Fragment>
                    <Grid item xs={12}><Alert color="info" icon={false}>
                        <strong>Tem dúvidas?</strong><br/> 
                        <Link href="/instrucoes.pdf" target="_blank">Clique aqui e acesse</Link> o passo a passo de como criar sua primeira oferta.
                    </Alert>
                    </Grid>
                    {dashboard?.data?.accepted_bids ? (
                    <Grid item xs={12}>
                    <Alert color="success" icon={false}>
                        <strong>Você tem {dashboard.data.accepted_bids} ofertas aguardando os dados da conta para emissão.</strong><br/> Vá para as <Link href="/minhas-ofertas">suas ofertas</Link> e selecione a conta.
                    </Alert>
                </Grid>) : null}
                    {dashboard?.data?.pending_banking_details ? (
                    <Grid item xs={12}><Alert color="error" icon={false}>
                        <strong>Preencha seus dados bancários!</strong><br/> Somente com estes dados poderemos enviar os seus pagamentos. <Link href="/meu-perfil">Clique aqui</Link> e preencha seus dados.
                    </Alert>
                </Grid>): null}
                <Grid item xs={12} sm={6} height={"50%"}>
                    <Paper elevation={1} sx={{
                        height: '100%',
                        padding: 2
                    }}>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            Meus Recebimentos Mensais
                        </Typography>
                        <div style={{
                            height: '100%'
                        }}>
                        <ResponsiveContainer
                            height={'90%'}
                        >
                            {!dashboard.loading ? (<LineChart
                            data={dashboard.data?.last_twelve_months_payments || []}
                            margin={{
                                top: 16,
                                right: 16,
                                bottom: 0,
                                left: 16,
                            }}
                            >

                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="month"
                                includeHidden
                                stroke={theme.palette.text.secondary}
                                style={theme.typography.body2}
                                tickFormatter={tick => {
                                    return capitalizeFirstLetter(moment(`${tick}/01`).format('MMM/YYYY'))
                                }}
                                tickLine
                                tickCount={12}
                            >
                            </XAxis>
                            <YAxis
                                stroke={theme.palette.text.secondary}
                                style={theme.typography.body2}
                                tickFormatter={tick => {
                                    return formatMoney(tick)
                                }}
                                tick
                            >
                            </YAxis>
                            <Line
                                isAnimationActive={false}
                                type="monotone"
                                dataKey="amount"
                                stroke={theme.palette.primary.main}
                            />
                            <Tooltip 
                                formatter={(value: number) => {
                                    return [formatMoney(value)]
                                }}
                                labelFormatter={label => {
                                    return capitalizeFirstLetter(moment(`${label}/01`).format('MMMM/YYYY'))
                                }}
                                labelStyle={{
                                    fontWeight: 'bold'
                                }}
                            />
                            <Legend 
                                formatter={() => {
                                    return "Valor"
                                }}
                                fontWeight="bold"
                            /> 
                            </LineChart>) : ( <div />)}
                        </ResponsiveContainer>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} height={"50%"}>
                    <Paper elevation={1} sx={{
                        height: '100%',
                        padding: 2
                    }}>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            Valores Médios de Mercado
                        </Typography>
                        <div style={{
                            height: '100%'
                        }}>
                        <ResponsiveContainer
                            height={'90%'}
                        >
                            {!dashboard.loading ? (<LineChart
                                data={dashboard.data?.last_seven_days_k_price || []}
                                margin={{
                                    top: 16,
                                    right: 16,
                                    left: 16,
                                }}
                            >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="day"
                                includeHidden
                                stroke={theme.palette.text.secondary}
                            >
                                
                            </XAxis>
                            <YAxis
                                stroke={theme.palette.text.secondary}
                                style={theme.typography.body2}
                                tickFormatter={tick => {
                                    return formatMoney(tick)
                                }}
                                tick
                            >
                            </YAxis>
                            {
                                Object.keys((dashboard.data?.last_seven_days_k_price || [])[0] || {})?.map(item => {
                                    if (item !== 'day') {
                                        return (
                                            <Line
                                                isAnimationActive={false}
                                                type="monotone"
                                                dataKey={item}
                                                stroke={COMPANY_OPTIONS.find(company => company.value === item)?.color}
                                            />
                                        )
                                    }

                                    return null
                                })
                            }
                            
                            <Tooltip 
                                formatter={(value: number, name) => {
                                    return [formatMoney(value), COMPANY_OPTIONS.find(item => item.value === name)?.label]
                                }}
                                itemStyle={{
                                    color: theme.palette.primary.main
                                }}
                                labelStyle={{
                                    fontWeight: 'bold'
                                }}
                                wrapperStyle={{
                                    zIndex: 10
                                }}
                            />
                            <Legend 
                                formatter={value => {
                                    return COMPANY_OPTIONS.find(item => item.value === value)?.label
                                }}
                                fontWeight="bold"
                            /> 
                            </LineChart>) : <div/>}
                        </ResponsiveContainer>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} height={"50%"}>
                <Paper elevation={1} sx={{
                        height: '100%',
                        padding: 2
                    }}>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            Minhas Negociações
                        </Typography>
                        <div style={{
                            height: '100%'
                        }}>
                        <ResponsiveContainer
                            height={'90%'}
                        >
                            {!dashboard.loading ? (
                                <PieChart
                                    margin={{
                                        top: 16,
                                        right: 16,
                                        left: 16,
                                        bottom: 16,
                                    }}
                                >
                                <Pie 
                                    data={dashboard?.data?.miles_used} 
                                    dataKey="amount" 
                                    label={(payload) => {
                                        return `${COMPANY_OPTIONS.find(item => item.value === payload.company)?.label}: ${Number(payload.amount).toLocaleString('pt-BR')} (${currency(payload.percent).multiply(100).value}%)`
                                    }}
                                >
                                {
                                    dashboard?.data?.miles_used?.map((item: { company: string }) => {
                                        return (
                                            <Cell 
                                                key={item.company} 
                                                fill={COMPANY_OPTIONS.find(company => company.value === item.company)?.color}
                                            />
                                        )
                                    })
                                }
                                </Pie>
                                <Tooltip 
                                    formatter={(value: number, index) => {
                                        return [
                                            `${COMPANY_OPTIONS.find(company => company.value === dashboard?.data?.miles_used[index].company)?.label}: ${Number(value).toLocaleString('pt-BR')}`
                                        ]
                                    }}
                                    labelFormatter={label => {
                                        return COMPANY_OPTIONS.find(company => company.value === label.company)?.label
                                    }}
                                    labelStyle={{
                                        fontWeight: 'bold'
                                    }}
                                />
                                <Legend 
                                    formatter={value => {
                                        return COMPANY_OPTIONS.find(company => company.value === dashboard?.data?.miles_used[value].company)?.label
                                    }}
                                    fontWeight="bold"
                                /> 
                              </PieChart>
                            ) : ( <div />)}
                        </ResponsiveContainer>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} height={"50%"}>
                <Paper elevation={1} sx={{
                        height: '100%',
                        padding: 2
                    }}>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            Minhas Ofertas
                        </Typography>
                        <div style={{
                            height: '100%'
                        }}>
                        <ResponsiveContainer
                            height={'90%'}
                        >
                            {!dashboard.loading ? (
                                <BarChart
                                    margin={{
                                        top: 16,
                                        right: 16,
                                        left: 16,
                                        bottom: 16,
                                    }}
                                    data={dashboard?.data?.my_bids_balance}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="company" tickFormatter={label => {
                                        return COMPANY_OPTIONS.find(item => item.value === label)?.label as string
                                    }}/>
                                    <YAxis 
                                        allowDecimals={false}
                                    />
                                    <Tooltip 
                                        formatter={(_, status) => {
                                            return [_, getBidStatusLabel(status as string)]
                                        }}
                                        labelFormatter={label => {
                                            return COMPANY_OPTIONS.find(item => item.value === label)?.label
                                        }}
                                        labelStyle={{
                                            fontWeight: 'bold'
                                        }}
                                    />
                                    <Legend 
                                        formatter={(status) => {
                                            return getBidStatusLabel(status)
                                        }}
                                    />
                                    {
                                        [
                                            'ACCEPTED',
                                            'DENIED',
                                            'PENDING',
                                            'DONE',
                                            'AWAITING_ORDER',
                                            'ON_ORDER',
                                        ].map((status) => {
                                            return (
                                                <Bar key={status} dataKey={status} fill={(theme.palette[getBidStatusColor(status) as keyof Palette] as PaletteColor)?.main} />
                                            )
                                        })
                                    }
                              </BarChart>
                            ) : ( <div />)}
                        </ResponsiveContainer>
                        </div>
                    </Paper>
                </Grid>
                </Fragment>)}
            </Grid>
        </Layout>
    )
}