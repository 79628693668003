import useAxiosHook, { Options } from 'axios-hooks'
import { AxiosRequestConfig } from 'axios';
import { useMemo } from 'react';
import { configure } from 'axios-hooks';
import API from '../API';

configure({ axios: API, defaultOptions: {
  autoCancel: false
}})

export const useAxios = (params: string | AxiosRequestConfig, options?: Options) => { 
    const config: AxiosRequestConfig = useMemo(() => {
        let newConfig: AxiosRequestConfig = {}

        if (typeof params === "string") {
            newConfig.url = params
        } else {
            newConfig = {
                ...params,
            }
        }

        return {
            ...newConfig
        }
    }, [params])

    return useAxiosHook(config, options)
}