import { DateRangePicker, Grid, Modal, MultipleAutocomplete, MultipleAutocompleteOption, Typography } from "../../components";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid, PieChart, Pie, Cell, BarChart, Bar } from 'recharts';
import { IconButton, Palette, PaletteColor, useTheme } from "@mui/material";
import moment from "moment";
import { COMPANY_OPTIONS } from "../../constants";
import { Fragment, useContext, useEffect, useMemo } from "react";
import {useAxios, useToggle} from "../../hooks";
import { useFormik } from "formik";
import AuthContext from "../../context/auth";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import currency from "currency.js";
import { randomHexColorCode } from "../../utils";

export function MilesNegotiatedPerUser () { 
    const { me } = useContext(AuthContext)

    const [isFilterModalVisible, setIsFilterModalVisible] = useToggle(false)

    const [userReferrals] = useAxios(`/user-referral?inviter_id=${me?.id.toString()}`)

    const [dashboard, refetchDashboard] = useAxios({
        url: `/user-referral/miles-negotiated-per-user?inviter_id=${me?.id.toString()}`,
    }, {
        manual: true
    })

    const searchFormik = useFormik({
        initialValues: {
            from: moment().subtract(1, 'month').toDate(),
            to: moment().toDate(),
            user_id: [] as Array<string | number>,
            company: [] as Array<string | number>
        },
        onSubmit: values => {
            setIsFilterModalVisible.setFalse()
            refetchDashboard({
                params: {
                    from: values.from,
                    to: values.to,
                    user_id: values.user_id,
                    company: values.company
                }
            })
        }
    })

    const userOptions = useMemo(() => {
        return userReferrals?.data?.map((item: {name: string, id: number}) => ({
            value: item.id,
            label: item.name
        })) || []
    }, [userReferrals.data])

    useEffect(() => {
        const values = searchFormik.values

        refetchDashboard({
            params: {
                from: values.from,
                to: values.to
            }
        })
    }, [])
    
    return (
        <Fragment>
            <Modal
                open={isFilterModalVisible}
                onClose={() => { 
                    setIsFilterModalVisible.setFalse()
                    searchFormik.resetForm()
                }}
                title="Filtrar"
                confirmLabel="Confirmar"
                onConfirm={searchFormik.submitForm}
                bodyCustomCss={{
                    width: 500
                }}
            >
                <Grid container spacing={1}> 
                    <Grid item xs={12}>
                        <MultipleAutocomplete
                            variant="outlined"
                            label="Companhia"
                            onChange={(value) => {
                                searchFormik.setFieldValue('company', (value as MultipleAutocompleteOption[])?.map(item => item.value))
                            }}
                            options={COMPANY_OPTIONS}
                            value={COMPANY_OPTIONS.filter((item: MultipleAutocompleteOption) => searchFormik.values.company.includes(item.value))}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MultipleAutocomplete
                            variant="outlined"
                            label="Usuário"
                            onChange={(value) => {
                                searchFormik.setFieldValue('user_id', (value as MultipleAutocompleteOption[])?.map(item => item.value))
                            }}
                            options={userOptions}
                            value={userOptions.filter((item: MultipleAutocompleteOption) => searchFormik.values.user_id.includes(item.value))}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DateRangePicker
                            startDate={searchFormik.values.from}
                            endDate={searchFormik.values.to}
                            onChange={(value) => {
                                searchFormik.setFieldValue('from', value.startDate)
                                searchFormik.setFieldValue('to', value.endDate)
                            }}
                        />
                    </Grid>
                </Grid>
            </Modal>
            <div
                style={{
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column',
                    flex: 1
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: 10,
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <Typography component="h2" variant="h6" color="primary" fontSize={20} flex={1}>
                        Milhas Negociadas (por usuário)
                    </Typography>
                    <div>
                        <IconButton
                            color="primary"
                            onClick={setIsFilterModalVisible.setTrue}
                        >
                            <FilterAltIcon />
                        </IconButton>
                    </div>
                </div>
                <div style={{
                    height: '100%'
                }}>
                    <ResponsiveContainer
                        height={'100%'}
                    >
                        {!dashboard.loading && dashboard?.data ? (
                            <PieChart
                                margin={{
                                    top: 16,
                                    right: 16,
                                    left: 16,
                                    bottom: 16,
                                }}
                            >
                            <Pie
                                data={dashboard?.data || []} 
                                dataKey="amount" 
                            >
                            {
                                dashboard?.data?.map((item: { user_id: string }) => {
                                    return (
                                        <Cell
                                            key={item.user_id} 
                                            fill={randomHexColorCode()}
                                        />
                                    )
                                })
                            }
                            </Pie>
                            <Tooltip
                                formatter={(value: number, index, item) => {
                                    return [
                                        `${userOptions.find((user: { value: string}) => user.value === dashboard?.data[index].user_id)?.label}: ${Number(value).toLocaleString('pt-BR')} / ${Number(dashboard?.data[index]?.miles).toLocaleString('pt-BR', {
                                            notation: 'compact',
                                            maximumFractionDigits: 3
                                        })}`
                                    ]
                                }}
                                labelFormatter={index => {
                                    const userId = dashboard.data[index].user_id

                                    return userOptions.find((company: {value: string}) => company.value === userId)?.label
                                }}
                                labelStyle={{
                                    fontWeight: 'bold'
                                }}
                            />
                            {/* <Legend
                                formatter={(value, entry) => {
                                    return userOptions.find((user: {value: string}) => user.value === dashboard?.data[value].user_id)?.label
                                }}
                                fontWeight="bold"
                            />  */}
                        </PieChart>
                        ) : ( <div />)}
                    </ResponsiveContainer>
                </div>
            </div>
        </Fragment>
    )
}