import ReactPhoneInput, {PhoneInputProps} from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import pt from 'react-phone-input-2/lang/pt.json'
import { useTheme } from '@mui/material'

export const PhoneInput = (props: PhoneInputProps & { label: string, error?: boolean }) => {
    const theme = useTheme()

    const errorStyles = {
        borderColor: theme.palette.error.main,
        bordeborderWidth: 2
    }

    return (
        <ReactPhoneInput
            {...props}
            specialLabel={props.label}
            localization={pt}
            inputStyle={{
                width: '100%',
                ...(props.error ? errorStyles : {})
            }}
            country="br"
            countryCodeEditable={false}
        />
    )
}