import { Grid, Button, Typography, Loader } from "../../components";
import { useCallback, useContext, useEffect, useState } from "react";
import AuthContext from "../../context/auth";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player/youtube";
import { useAxios } from "../../hooks";
import { APP_NAME, APP_WELCOME_VIDEO } from "@next-pontos/settings";

export function Welcome () { 
    const [isFullWatched, setIsFullWatched] = useState(false)
    const { me } = useContext(AuthContext)
    const navigate = useNavigate()

    const [submittedVideoWatched, submitVideoWatch] = useAxios({
        url: '/user/first-login',
        method: 'PUT',
    }, {
        manual: true
    })

    useEffect(() => {
        if (me) {
            if (!me.is_first_login) {
                navigate('/home')
            }
        }
    }, [me, navigate])

    const submit = useCallback(async () => {
        await submitVideoWatch()

        navigate('/home')
    }, [submitVideoWatch, navigate])

    return (
        <Grid container justifyContent="center" minHeight={"100vh"} alignItems="center" style={{
            backgroundColor: '#ebebeb',
            backgroundImage: 'url(/background.png)',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }}>
            {submittedVideoWatched.loading ? 
                <Loader />
            : (<Grid item xs={12} textAlign="center" display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <img alt={`${APP_NAME} Logo`} src='/logo.png' style={{
                height: '150px',
                marginBottom: 15
            }}/>
            <Typography variant="subtitle1">
                Assista o vídeo abaixo sobre como funciona a {APP_NAME}.
            </Typography>
            <Typography>
                Em seguida, clique no botão para acessar.
            </Typography>
                <ReactPlayer width={700} height={395} controls={false} url={APP_WELCOME_VIDEO} style={{marginTop: 15, marginBottom: 15}} onEnded={() => setIsFullWatched(true)} />
                <div>
                    <Button disabled={!isFullWatched} variant="contained" fullWidth size="large" color="primary" onClick={submit}>Acessar o Sistema</Button>
                </div>
            </Grid>)}
        </Grid>
    )
}