import { ThemeProvider } from './components';
import { AuthContextProvider } from './context/auth';
import RouterProvider from './routes'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/pt-br';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SnackbarProvider } from 'notistack'
import {useEffect} from 'react'
import TagManager from 'react-gtm-module';
import { CustomerAuthContextProvider } from './context/customerAuth';

function App() {
  useEffect(() => {
    if (performance.navigation.type === 2) {
      window.location.reload()
    }
  }, [])
  
  useEffect(() => {
    console.log('GTM ID', process.env.REACT_APP_GTM_ID)
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID as string
    };
    TagManager.initialize(tagManagerArgs);
}, []);


  return (
    <SnackbarProvider>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="br">
        <ThemeProvider>
          <RouterProvider />
        </ThemeProvider>
      </LocalizationProvider>
    </SnackbarProvider>
  );
}

export default App
