import { Button, Grid, Layout, Paper, Table, Loader, Autocomplete, Input, Typography, Alert, FileUploader } from "../../../components";
import moment from "moment";
import { COMPANY_OPTIONS, SUPPORT_TICKET_STATUS } from "../../../constants";
import { useAxios } from '../../../hooks'
import { Fragment, useContext, useEffect, useMemo, useRef } from "react";
import { AlertTitle, Divider, Link } from "@mui/material";
import { formatDateTime, nl2br } from "../../../utils";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import AuthContext from "../../../context/auth";
import AttachFileIcon from '@mui/icons-material/Download';
import { enqueueSnackbar } from "notistack";
import { CreateSupportTicketMessageValidationSchema } from "@next-pontos/validations";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';


export function Lead () {
    const params = useParams()
    const id = params.id

    const [lead] = useAxios(`/lead/${id}`)
    
    return (
        <Layout>
            <Grid container justifyContent="center" padding={4} spacing={2} height="100%">
                {(lead.loading) ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) : (
                    <Fragment>
                        <Grid item xs={5}>
                            <Paper elevation={1}>
                                <Grid container p={2} spacing={2}>
                                    <Grid item xs={12} marginBottom={2}>
                                        <Typography textAlign={"center"} variant="h5">Detalhes do Lead</Typography>
                                    </Grid>
                                    <Grid item xs={6} fontWeight={"bold"}>
                                        Data:
                                    </Grid>
                                    <Grid item xs={6} textAlign={"right"}>
                                        {formatDateTime(lead.data.created_at)}
                                    </Grid>
                                    <Grid item xs={6} fontWeight={"bold"}>
                                        Email:
                                    </Grid>
                                    <Grid item xs={6} textAlign={"right"}>
                                        {lead.data.email}
                                    </Grid>
                                    <Grid item xs={6} fontWeight={"bold"}>
                                        Convertido:
                                    </Grid>
                                    <Grid item xs={6} textAlign={"right"}>
                                        {lead.data.user?.id ? (
                                            <CheckIcon />
                                        ) : ( 
                                            <CloseIcon />
                                        )}
                                    </Grid>
                                    {lead.data.user?.id && (
                                        <Fragment>
                                            <Grid item xs={6} fontWeight={"bold"}>
                                                Usuário:
                                            </Grid>
                                            <Grid item xs={6} textAlign={"right"}>
                                                <Link href={`/usuarios/${lead.data.user.id}`}>{lead.data.user?.name}</Link>
                                            </Grid>
                                        </Fragment>
                                    )}
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={5}>
                            <Paper elevation={1}>
                                <Grid container p={2} spacing={2}>
                                    <Grid item xs={12} marginBottom={2}>
                                        <Typography textAlign={"center"} variant="h5">Ofertas</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Table 
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: 5
                                                    }
                                                }
                                            }}
                                            columns={[
                                                { field: 'id', headerName: 'ID', width: 50, type: 'string' },
                                                { field: 'created_at', headerName: 'Data', type: 'dateTime', width: 200, valueFormatter: ({value}) => {
                                                    return moment(value).format('DD/MM/YYYY HH:mm:ss')
                                                } },
                                                { field: 'company', headerName: 'Programa', type: 'string', flex: 1, valueGetter: ({value}) => {
                                                    return COMPANY_OPTIONS.find(item => item.value === value)?.label
                                                } },
                                                { field: 'amount', headerName: 'Quantidade', flex: 1, type: 'number', valueFormatter: ({value}) => Number(value).toLocaleString('pt-BR') },
                                                { field: 'origin', headerName: 'Origem', flex: 1, type: 'string' },
                                            ]}
                                            rows={lead?.data.lead_bid || []}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Fragment>
                )}
            </Grid>
        </Layout>
    )
}