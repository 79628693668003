import { PropsWithChildren, useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../../context/auth";

export const UnAuthRoute = ({ children }: PropsWithChildren): JSX.Element => {
    const { signed, isLoading } = useContext(AuthContext)

  if (signed && !isLoading) {
    return <Navigate to="/home" />
  }

  return children as unknown as JSX.Element;
};