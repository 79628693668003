import { useFormik } from "formik";
import { Grid, Paper, Button, Input, Typography, Loader } from "../../components";
import {ForgotPasswordSchema, SignInValidationSchema, UpdatePasswordSchema } from '@next-pontos/validations'
import { useContext, useMemo } from "react";
import AuthContext from "../../context/auth";
import { useLocation, useNavigate,  } from "react-router-dom";
import { Link, Tooltip } from "@mui/material";
import { useAxios } from "../../hooks";
import { enqueueSnackbar } from "notistack";

export function NewPassword () { 
    const navigate = useNavigate()
    const { search } = useLocation()

    const token = useMemo(() => {
        return (new URLSearchParams(search)).get('token')
    }, [search])

    const [updatedPassword, updatePassword] = useAxios({
        url: '/auth/password',
        method: 'PUT',
    }, {
        manual: true
    })

    const formik = useFormik({
        initialValues: {
            token,
            password: '',
            password_confirmation: ''
        },
        onSubmit: async (values) => {
            updatePassword({
                data: {
                    ...values,
                    token
                }
            })

            enqueueSnackbar('Senha alterada com sucesso', { variant: 'success' })

            navigate('/')
        },
        validationSchema: UpdatePasswordSchema,
        validateOnMount: true
    })
    
    return (
        <Grid container justifyContent="center" minHeight={"100vh"} alignItems="center" style={{
            backgroundColor: '#ebebeb',
            backgroundImage: 'url(/background.png)',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }}>
            {updatedPassword.loading ? (
                <Loader />
            ) : (<Grid item xs={12} sm={4}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container padding={2} spacing={2} justifyContent="center" textAlign={"center"}>
                        <Grid item xs={12}>
                            <img src="/logo.png" alt="Logo" style={{width: '50%'}}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Tooltip arrow title="Sua senha deve conter no mínimo 8 carateres, uma letra minúscula, uma letra maiúscula e um número.">
                                <Input 
                                    label="Senha"
                                    type="password"
                                    name="password"
                                    onChange={formik.handleChange}
                                    helperText={formik.touched.password && formik.errors.password}
                                    onBlur={formik.handleBlur}
                                    error={!!formik.touched.password && !!formik.errors.password}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                            <Input 
                                label="Confirmar Senha"
                                type="password"
                                name="password_confirmation"
                                onChange={formik.handleChange}
                                helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
                                onBlur={formik.handleBlur}
                                error={!!formik.touched.password_confirmation && !!formik.errors.password_confirmation}
                            />
                        </Grid>
                        <Grid item marginTop={2} xs={12}>
                            <Button disabled={!formik.isValid} type="submit">Enviar</Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>)}
        </Grid>
    )
}