import { TextFieldProps } from '@mui/material';
import { CurrencyInput as CurrInput, ICurrencyMaskProps } from 'react-currency-mask';
import { Input } from '../Input';

type CurrencyInputProps = TextFieldProps & {
    onChangeValue: ICurrencyMaskProps['onChangeValue']
    value?: number | string
}

export const CurrencyInput = (props: CurrencyInputProps) => {
    return (
        <CurrInput
            onChangeValue={props.onChangeValue}
            hideSymbol
            value={props.value}
            onBlur={props.onBlur}
            InputElement={
                <Input
                    {...props}
                />
            }
        />
    )
}