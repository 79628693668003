import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { PropsWithChildren } from 'react';
import {APP_THEME} from '@next-pontos/settings'

const theme = createTheme(APP_THEME)

export const ThemeProvider = ({children}: PropsWithChildren) => {
    return (
        <MuiThemeProvider theme={theme}>
            {children}
        </MuiThemeProvider>
    )
}