import { Typography } from "../../components";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from 'recharts';
import { useTheme } from "@mui/material";
import moment from "moment";
import { capitalizeFirstLetter } from "../../utils";
import { useMemo } from "react";
import {useAxios} from "../../hooks";

const twelveMonthsAgo = moment().subtract(11, 'months').toDate()
const monthsArray: Array<string> = []

for (let index = 0; index <= 11; index++) {
    const month = moment(twelveMonthsAgo).add(index, 'months').format('YYYY/MM')
    
    monthsArray.push(month)
}

export function NewCustomers () { 
    const theme = useTheme()

    const [customers] = useAxios(`/customer`)

    const dashboard = useMemo(() => {
        const lastTwelveMonthsReferrals = [...monthsArray].map(month => {
            const refs = customers?.data?.filter((ref: { created_at: Date }) => moment(ref.created_at).format('YYYY/MM') === month) || []

            return {
                month,
                amount: refs.length
            }
        })
        
        return lastTwelveMonthsReferrals || []
    }, [customers.data])
    
    return (
        <div
            style={{
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                flex: 1
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 10,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <Typography component="h2" variant="h6" color="primary" fontSize={20} flex={1}>
                Clientes Cadastrados
                </Typography>
            </div>
            <div style={{
                height: '100%'
            }}>
            <ResponsiveContainer
                height={'90%'}
            >
                {!customers.loading ? (
                    <LineChart
                        data={dashboard || []}
                        margin={{
                            top: 16,
                            right: 16,
                            bottom: 0,
                            left: 16,
                        }}
                    >

                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="month"
                    includeHidden
                    stroke={theme.palette.text.secondary}
                    style={theme.typography.body2}
                    tickFormatter={tick => {
                        return capitalizeFirstLetter(moment(`${tick}/01`).format('MMM/YYYY'))
                    }}
                    tickLine
                    tickCount={12}
                >
                </XAxis>
                <YAxis
                    stroke={theme.palette.text.secondary}
                    style={theme.typography.body2}
                    tickFormatter={tick => {
                        return Number(tick).toLocaleString('pt-BR')
                    }}
                    allowDecimals={false}
                    tick
                >
                </YAxis>
                <Line
                    isAnimationActive={false}
                    type="monotone"
                    dataKey="amount"
                    stroke={theme.palette.primary.main}
                />
                <Tooltip 
                    formatter={(value: number) => {
                        return [Number(value).toLocaleString()]
                    }}
                    labelFormatter={label => {
                        return capitalizeFirstLetter(moment(`${label}/01`).format('MMMM/YYYY'))
                    }}
                    labelStyle={{
                        fontWeight: 'bold'
                    }}
                />
                <Legend 
                    formatter={() => {
                        return "Clientes Cadastrados"
                    }}
                    fontWeight="bold"
                /> 
                </LineChart>) : ( <div />)}
            </ResponsiveContainer>
            </div>
        </div>
    )
}