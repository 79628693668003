import { Button, DatePicker, Grid, Input, Layout, Loader, PhoneInput, Switch, Typography } from "../../../components";
import { Paper } from "../../../components";
import { useFormik } from "formik";
import { CreateAccountValidationSchema } from "@next-pontos/validations";
import { useAxios } from "../../../hooks";
import { useContext, useMemo } from 'react'
import { formatCPF } from "../../../utils";
import AuthContext, { Company } from "../../../context/auth";
import { Tooltip } from "@mui/material";
import { COMPANY_OPTIONS } from "../../../constants";
import moment from "moment";

export function NewAccount () { 
    const [account, createAccount] = useAxios({
        url: '/account',
        method: 'POST'
    }, {manual: true})

    const { me } = useContext(AuthContext)

    const formik = useFormik({
        initialValues: {
            company: '',
            holder_name: '',
            holder_email: '',
            holder_cpf: '',
            holder_phone_number: '',
            login: '',
            password: '',
            secondary_password: '',
            is_membership_active: false,
            holder_birthday: '',
            membership_number: '',
        },
        onSubmit: async values => {
            await createAccount({
                data: values
            })

            window.location.replace(`/minhas-contas`)
        },
        validateOnMount: true,
        validationSchema: CreateAccountValidationSchema
    })

    const ALLOWED_COMPANY_OPTIONS = useMemo(() => {
        const acceptedBidCompanies = me?.bids.filter(item => item.status === 'ACCEPTED').map(item => item.company)
        
        return COMPANY_OPTIONS.filter(item => acceptedBidCompanies?.includes(item.value as Company))
    }, [me])

    return (
        <Layout>
            <Grid container justifyContent="center" minHeight={"calc(100vh - 64px)"} alignItems="center" padding={3}>
                {account.loading ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) : (<Grid item xs={12} sm={5}>
                    <Paper elevation={1}>
                        <form onSubmit={formik.handleSubmit}>
                        <Grid container padding={2} spacing={2} justifyContent="center">
                            <Grid item xs={12} marginBottom={2}>
                                <Typography textAlign={"center"} variant="h5">Nova Conta</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}> 
                                <Tooltip title="Você apenas pode adicionar contas para suas ofertas aceitas." placement="top">
                                    <Input 
                                        label="Companhia"
                                        name="company"
                                        select
                                        options={ALLOWED_COMPANY_OPTIONS}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.company}
                                        helperText={formik.touched.company && formik.errors.company}
                                        error={!!formik.touched.company && !!formik.errors.company}
                                    />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} sm={6} display="flex" alignItems="center" justifyContent="center"> 
                                <Switch 
                                    label="Possui clube ativo?"
                                    checked={formik.values.is_membership_active}
                                    onChange={() => {
                                        formik.setFieldValue('is_membership_active', !formik.values.is_membership_active, false)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}> 
                                <Input 
                                    label="Nome do Titular"
                                    name="holder_name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.holder_name}
                                    helperText={formik.touched.holder_name && formik.errors.holder_name}
                                    error={!!formik.touched.holder_name && !!formik.errors.holder_name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}> 
                                <Input 
                                    label="E-mail do Titular"
                                    name="holder_email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.holder_email}
                                    helperText={formik.touched.holder_email && formik.errors.holder_email}
                                    error={!!formik.touched.holder_email && !!formik.errors.holder_email}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DatePicker 
                                    label="Data de Nascimento do Titular"
                                    value={formik.values.holder_birthday ? moment(formik.values.holder_birthday) : null}
                                    onChange={(value) => {
                                        formik.setFieldValue('holder_birthday', value?.toDate(), true)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}> 
                                <Tooltip
                                    title="Preencha este campo com o número de fidelidade da companhia. Caso não haja preencha com o CPF."
                                >
                                    <Input 
                                        label="Número de Fidelidade"
                                        name="membership_number"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.membership_number}
                                        helperText={formik.touched.membership_number && formik.errors.membership_number}
                                        error={!!formik.touched.membership_number && !!formik.errors.membership_number}
                                    />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} sm={6}> 
                                <Input 
                                    label="CPF do Titular"
                                    name="holder_cpf"
                                    onChange={({target}) => {
                                        formik.setFieldValue('holder_cpf', formatCPF(target.value))
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.holder_cpf}
                                    helperText={formik.touched.holder_cpf && formik.errors.holder_cpf}
                                    error={!!formik.touched.holder_cpf && !!formik.errors.holder_cpf}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}> 
                                <PhoneInput
                                    label="Telefone do Titular"
                                    inputProps={{
                                        name: 'holder_phone_number'
                                    }}
                                    onChange={value => {
                                        formik.setFieldValue('holder_phone_number', value, true)
                                    }}
                                    value={formik.values.holder_phone_number}
                                    // helperText={formik.touched.phone_number && formik.errors.phone_number}
                                    onBlur={formik.handleBlur}
                                    error={!!formik.touched.holder_phone_number && !!formik.errors.holder_phone_number}
                                />
                            </Grid>
                            <Grid item xs={12}> 
                                <Input 
                                    label="Login"
                                    name="login"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.login}
                                    helperText={formik.touched.login && formik.errors.login}
                                    error={!!formik.touched.login && !!formik.errors.login}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}> 
                                <Input 
                                    label="Senha"
                                    name="password"
                                    type="password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                    helperText={formik.touched.password && formik.errors.password}
                                    error={!!formik.touched.password && !!formik.errors.password}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}> 
                                <Tooltip title="Alguns programas de fidelidade possuem uma senha secundária. Caso não possua, não é necessário preencher este campo.">
                                <Input 
                                    label="Contrassenha"
                                    name="secondary_password"
                                    type="password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.secondary_password}
                                    helperText={formik.touched.secondary_password && formik.errors.secondary_password}
                                    error={!!formik.touched.secondary_password && !!formik.errors.secondary_password}
                                />
                                </Tooltip>
                            </Grid>
                            <Grid item marginTop={2}>
                                <Button disabled={!formik.isValid} type="submit">Criar Conta</Button>
                            </Grid>
                        </Grid>
                        </form>
                    </Paper>
                </Grid>)}
            </Grid>
        </Layout>
    )
}