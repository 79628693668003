import { Button, Grid, Layout, Paper, Table, Loader, Autocomplete, Input, Typography, Alert, FileUploader } from "../../../components";
import moment from "moment";
import { COMPANY_OPTIONS, SUPPORT_TICKET_STATUS } from "../../../constants";
import { useAxios } from '../../../hooks'
import { Fragment, useContext, useEffect, useMemo, useRef } from "react";
import { AlertTitle, Divider } from "@mui/material";
import { formatDateTime, nl2br } from "../../../utils";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import AuthContext from "../../../context/auth";
import AttachFileIcon from '@mui/icons-material/Download';
import { enqueueSnackbar } from "notistack";
import { CreateSupportTicketMessageValidationSchema } from "@next-pontos/validations";

export function SupportTicket () {
    const params = useParams()
    const supportTicketId = params.id

    const chatContainerRef = useRef<HTMLDivElement>(null)

    const {me} = useContext(AuthContext)
    const [ticket, refetchTicket] = useAxios(`/support-ticket/${supportTicketId}`)
    const [messages, refetchMessages] = useAxios(`/support-ticket/${supportTicketId}/message`)

    const [updatedTicket, updateTicket] = useAxios({
        url: `/support-ticket/${supportTicketId}`,
        method: 'PUT'
    }, {
        manual: true
    })

    const [_, downloadFile] = useAxios({
        url: '/file',
        method: 'GET'
    }, {
        manual: true
    })

    const [createdTicketMessage, createTicketMessage] = useAxios({
        url: '/support-ticket',
        method: 'PUT'
    }, {
        manual: true
    })
    
    const supportTicketFormik = useFormik({
        initialValues: {
            message: '',
            file: null,
        },
        validationSchema: CreateSupportTicketMessageValidationSchema,
        onSubmit: async (values, helpers) => {
            const formData = new FormData();
            
            formData.append('message', values.message)

            if (values.file) {
                formData.append('file', values.file)
            }
            
            const {status} = await createTicketMessage({
                url: `/support-ticket/${supportTicketId}/message`,
                method: 'POST',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })

            if (status === 201) {
                helpers.resetForm({
                    values: {
                        file: null,
                        message: ''
                    }
                })

                enqueueSnackbar('Mensagem enviada com suceso!', {
                    variant: 'success'
                })

                await refetchMessages()

                if (chatContainerRef.current) {
                    chatContainerRef.current.scrollTop = chatContainerRef.current?.scrollHeight
                }
            }
            
        }
    })

    const bidOptions = useMemo(() => {
        if (!ticket.data?.bid_id) {
            return []
        }

        return [
            {
                value: 1,
                label: `#${ticket.data.bid_id} - ${Number(ticket.data.bid.amount).toLocaleString('pt-BR')} - ${COMPANY_OPTIONS.find(item => item.value === ticket.data.bid.company)?.label}`
            }
        ]
    }, [ticket.data])

    useEffect(() => {
        if (messages.data && chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current?.scrollHeight
        }
    }, [messages.data])
    
    return (
        <Layout>
            <Grid container justifyContent="center" padding={4} spacing={2} height="100%" alignItems="center">
                {(ticket.loading || updatedTicket.loading) ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) : (
                    <Fragment>
                        <Grid item xs={12} sm={6}>
                        <form onSubmit={supportTicketFormik.handleSubmit}>
                            <Paper elevation={1}>
                                <Grid container p={2} spacing={2}>
                                <Grid item xs={12} >
                                    <Typography textAlign={"center"} variant="h5">Chamado #{supportTicketId}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Input 
                                        name="status"
                                        label="Status"
                                        select
                                        options={SUPPORT_TICKET_STATUS}
                                        value={ticket.data.status}
                                        onChange={async (e) => {
                                            const { status } = await updateTicket({
                                                data: {
                                                    status: e.target.value
                                                }
                                            })
                                            
                                            if (status === 200) {
                                                enqueueSnackbar('Status atualizado com sucesso!', {
                                                    variant: 'success'
                                                })

                                                refetchTicket().then(() => refetchMessages())
                                            }
                                        }}
                                    />
                                    </Grid>
                                    {ticket.data.bid_id && (<Grid item xs={12}> 
                                        <Autocomplete
                                            label="Oferta"
                                            onChange={() => {}}
                                            options={bidOptions}
                                            disabled
                                            value={bidOptions[0]}
                                        />
                                    </Grid>)}
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid
                                        item xs={12} 
                                    >
                                        <div
                                            ref={chatContainerRef}
                                            style={{
                                            height: 300,
                                            overflowY: 'scroll'
                                            }} 
                                        >
                                            {
                                                (messages.loading || createdTicketMessage.loading) ? (
                                                    <div style={{
                                                        display: 'flex',
                                                        flex: 1,
                                                        height: '100%',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>
                                                        <Loader />
                                                    </div>
                                                ) : (
                                                    <Fragment>
{
                                           (messages.data || []).map((item: { user: { id: number, name: string }, message: string, created_at: Date, file_id: number }, index: number) => {
                                            return (
                                                <div style={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    justifyContent: me?.id !== item.user.id ? 'left' : 'right'
                                                }}>
                                                    <Alert icon={false} style={{
                                                        width: '80%',
                                                        marginTop: index > 0 ? 10 : undefined
                                                    }} color="info">
                                                        <AlertTitle>
                                                            {formatDateTime(item.created_at)} - {item.user.name}
                                                        </AlertTitle>
                                                        <p dangerouslySetInnerHTML={{
                                                            __html: nl2br(item.message)
                                                        }} />
                                                        {item.file_id && (<Button variant="text" 
                                                        onClick={() => {
                                                            downloadFile({
                                                                url: `/file/${item.file_id}`,
                                                                responseType: 'blob'
                                                            }).then((res) => {
                                                                if (res.status === 200) {
                                                                    const blobUrl = URL.createObjectURL(res.data);
    
                                                                    window.open(blobUrl, '_blank')
                                                                }
                                                            })
                                                        }}>
                                                            <AttachFileIcon />
                                                            Baixar Anexo
                                                        </Button>)}
                                                    </Alert>
                                                </div>
                                            )
                                           })
                                        }
                                                    </Fragment>
                                                )
                                            }
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}> 
                                        <Input 
                                            name="message"
                                            label="Mensagem"
                                            multiline
                                            maxRows={4}
                                            rows={4}
                                            disabled={ticket.data.status === 'CLOSED'}
                                            value={supportTicketFormik.values.message}
                                            onChange={supportTicketFormik.handleChange}
                                            onBlur={supportTicketFormik.handleBlur}
                                            helperText={supportTicketFormik.touched.message && supportTicketFormik.errors.message}
                                            error={!!supportTicketFormik.touched.message && !!supportTicketFormik.errors.message}
                                        />
                                    </Grid>
                                    <Grid item xs={12}> 
                                        <FileUploader 
                                            disabled={ticket.data.status === 'CLOSED'}
                                            label="Anexo"
                                            onChange={(value) => {
                                                supportTicketFormik.setFieldValue('file', value, true)
                                            }}
                                            value={supportTicketFormik.values.file}
                                            inputProps={{
                                                accept: 'image/*, .pdf'
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} textAlign="center"> 
                                        <Button 
                                            disabled={ticket.data.status === 'CLOSED'}
                                            type="submit"
                                        > 
                                            Enviar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                            </form>
                        </Grid>
                    </Fragment>
                )}
            </Grid>
        </Layout>
    )
}