import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiSwitch, {SwitchProps} from '@mui/material/Switch';

export function Switch(props: SwitchProps & {label?: string}) {
  return (
    <FormGroup>
      <FormControlLabel control={<MuiSwitch {...props} />} label={props.label} />
    </FormGroup>
  );
}

