import { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { CustomerSignInValidationSchema, SignInValidationSchema } from '@next-pontos/validations'
import { useAxios } from "../hooks";
import { Loader } from "../components";

export interface Customer {
    id: number
    email: string
    name: string 
}

interface CustomerAuthContextValues {
    signed: boolean,
    isLoading: boolean,
    signIn: (values: typeof CustomerSignInValidationSchema.__outputType) => void
    signOut: (callback: () => void) => void
    me: Customer | null
}

const CustomerAuthContext = createContext({} as CustomerAuthContextValues);

export const CustomerAuthContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [token, setToken] = useState('')

    const [me, refetchMe] = useAxios('/customer/me')
    
    const [login, loginAPI] = useAxios({
        url: '/auth/customer/signin',
        method: 'POST'
    }, {
        manual: true
    })

    const signIn = useCallback(async (values: typeof CustomerSignInValidationSchema.__outputType) => {
        const { data } = await loginAPI({
            data: values
        })
        storeToken(data.token)
    }, [loginAPI])

    const signOut = useCallback((callback: () => void) => {
        window?.localStorage?.removeItem('@next-pontos/customer-token')

        setToken('')
        callback()
    }, [])

    const storeToken = (token: string) => {
        window?.localStorage?.setItem('@next-pontos/customer-token', token)

        setToken(token)
    }

    useEffect(() => {
        const storedToken = window.localStorage.getItem('@next-pontos/customer-token') || ''

        setToken(storedToken)
    }, [])

    useEffect(() => {
        if (token) {
            refetchMe()
        }
    }, [token])
    
    const isLoading = useMemo(() => {
        return login.loading || me.loading
    }, [login.loading, me.loading])

    if (isLoading) {
        return (
            <div style={{
                display: 'flex',
                flex: '1',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Loader />
            </div>
        )
    }

 return (
   <CustomerAuthContext.Provider value={{ 
        signed: Boolean(me.data) && Boolean(token), 
        isLoading: isLoading,
        signIn,
        signOut,
        me: me.data
    }}>
     {children}
   </CustomerAuthContext.Provider>
 );
};

export default CustomerAuthContext;