import { Alert, DateRangePicker, Grid, Layout, Loader, MultipleAutocomplete, MultipleAutocompleteOption, Paper, Typography } from "../../components";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid, PieChart, Pie, Cell, BarChart, Bar } from 'recharts';
import { Palette, PaletteColor, useTheme } from "@mui/material";
import moment from "moment";
import { capitalizeFirstLetter, formatMoney, getBidStatusColor, getBidStatusLabel } from "../../utils";
import { COMPANY_OPTIONS } from "../../constants";
import { Fragment, useEffect, useMemo } from "react";
import { Link } from "@mui/material";
import currency from "currency.js";
import {useAxios} from "../../hooks";
import { Orders } from "./Orders";
import { useFormik } from "formik";

export function MilesNegotiated () { 
    const theme = useTheme()

    const [dashboard, refetchDashboard] = useAxios({
        url: '/dashboard/miles-negotiated',
    }, {
        manual: true
    })

    const searchFormik = useFormik({
        initialValues: {
            from: moment().subtract(1, 'month').toDate(),
            to: moment().toDate(),
            user_id: [] as Array<string | number>
        },
        onSubmit: values => {}
    })

    const values = searchFormik.values

    useEffect(() => {
        refetchDashboard({
            params: {
                from: values.from,
                to: values.to,
                user_id: values.user_id
            }
        })
    }, [values])

    const [users] = useAxios('/user') 

    const userOptions = useMemo(() => {
        return users?.data?.filter(({role}: {role: 'ADMIN' | 'SELLER'}) => role === 'ADMIN' || role === 'SELLER').map((item: {name: string, id: number}) => ({
            value: item.id,
            label: item.name
        })) || []
    }, [users.data])
    
    return (
        <div
            style={{
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                flex: 1
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 10,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <Typography component="h2" variant="h6" color="primary" fontSize={20} flex={1}>
                Milhas Negociadas
                </Typography>
                <div 
                    style={{
                        display: 'flex',
                        flex: 2,
                        justifyContent: 'space-between'
                    }}
                >   
                    <MultipleAutocomplete
                        variant="outlined"
                        label="Emissor"
                        onChange={(value) => {
                            searchFormik.setFieldValue('user_id', (value as MultipleAutocompleteOption[])?.map(item => item.value))
                        }}
                        options={userOptions}
                        value={userOptions.filter((item: MultipleAutocompleteOption) => searchFormik.values.user_id.includes(item.value))}
                        style={{
                            marginRight: 5,
                            width: '60%',
                        }}
                    />
                    <DateRangePicker
                        startDate={searchFormik.values.from}
                        endDate={searchFormik.values.to}
                        onChange={(value) => {
                            searchFormik.setFieldValue('from', value.startDate)
                            searchFormik.setFieldValue('from', value.startDate)
                        }}
                    />
                </div>
            </div>
        <div style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Typography variant="h3" color={theme.palette.primary.main}>
                {Number(dashboard.data?.miles).toLocaleString('pt-BR', {
                    notation: 'compact',
                    maximumFractionDigits: 3
                })}
            </Typography>
        </div>
        </div>
    )
}