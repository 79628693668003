import { useFormik } from "formik";
import { Grid, Paper, Button, Input, Typography, Loader } from "../../components";
import {ForgotPasswordSchema, SignInValidationSchema } from '@next-pontos/validations'
import { useContext } from "react";
import AuthContext from "../../context/auth";
import { useNavigate } from "react-router-dom";
import { Link } from "@mui/material";
import { useAxios } from "../../hooks";
import { enqueueSnackbar } from "notistack";

export function ForgotPassword () { 
    const navigate = useNavigate()

    const [forgotPassword, sendPasswordRecoveryLink] = useAxios({
        url: '/auth/forgot-password',
        method: 'POST',
    }, {
        manual: true
    })

    const formik = useFormik({
        initialValues: {
            login: '',
        },
        onSubmit: async (values, {resetForm}) => {
            sendPasswordRecoveryLink({
                data: values
            })

            enqueueSnackbar('Link de recuperação enviado!', { variant: 'success' })
            resetForm()
        },
        validationSchema: ForgotPasswordSchema,
        validateOnMount: true
    })
    
    return (
        <Grid container justifyContent="center" minHeight={"100vh"} alignItems="center" style={{
            backgroundColor: '#ebebeb',
            backgroundImage: 'url(/background.png)',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }}>
            {forgotPassword.loading ? (
                <Loader />
            ) : (<Grid item xs={12} sm={4}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container padding={2} spacing={2} justifyContent="center" textAlign={"center"}>
                        <img src="/logo.png" alt="Logo" style={{width: '50%'}}/>
                        <Grid item xs={12}> 
                            <Input 
                                label="Email ou CPF"
                                name="login"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.login}
                                helperText={formik.touched.login && formik.errors.login}
                                error={!!formik.touched.login && !!formik.errors.login}
                            />
                        </Grid>
                        <Grid item marginTop={2} xs={12}>
                            <Button disabled={!formik.isValid} type="submit">Enviar</Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>)}
        </Grid>
    )
}