import { Button, Grid, Layout, Loader, Table, Typography } from "../../../components";
import { Paper } from "../../../components";
import { Chip } from "@mui/material";
import { formatDateTime, formatMoney, getBidStatusColor, getBidStatusLabel, getOrderConfirmationColor, getOrderConfirmationLabel, getPaymentStatusColor, getPaymentStatusLabel } from "../../../utils";
import { useParams } from "react-router-dom";
import { COMPANY_OPTIONS } from "../../../constants";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useAxios} from "../../../hooks";

export function Bid () { 
    const params = useParams()

    const [bid] = useAxios(`/bid/${params.id}`)
    
    return (
        <Layout>
            {bid.loading ? (
                <div style={{
                    display: 'flex',
                    flex: '1',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Loader />
                </div>
            ) : (<Grid container justifyContent="center" gap={4} minHeight={"calc(100vh - 64px)"} alignItems="flex-start" paddingTop={5} >
                <Grid item xs={12} sm={4}>
                    <Paper elevation={1}>
                        <Grid container padding={2} spacing={2} justifyContent="center">
                            <Grid item xs={12} marginBottom={2}>
                                <Typography textAlign={"center"} variant="h5">Oferta</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            ID:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography textAlign="end">
                                            {bid?.data?.id}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Data:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography textAlign="end">
                                            {formatDateTime(bid?.data?.created_at)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Fornecedor:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography textAlign="end">
                                            {bid?.data?.user?.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Status:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="right">
                                        <Chip label={getBidStatusLabel(bid?.data?.status)} color={getBidStatusColor(bid?.data?.status)}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Motivo:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="right">
                                        {bid.data.message}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Companhia:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography textAlign="end">
                                            {COMPANY_OPTIONS.find(company => company.value === bid?.data?.company)?.label}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Valor do Milheiro:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography textAlign="end">
                                            {formatMoney(bid?.data?.price_per_k)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Quantidade:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography textAlign="end">
                                            {Number(bid.data?.amount).toLocaleString('pt-BR')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            CPFs:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography textAlign="end">
                                            {bid?.data?.pax === null ? '∞' : bid?.data?.pax}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Paper elevation={1} >
                        <Grid container padding={2} spacing={2}>
                            <Grid item xs={12} marginBottom={2}>
                                <Typography textAlign={"center"} variant="h5">Emissões</Typography>
                            </Grid>
                            <Grid item xs={12}>
                            <Table 
                                columns={[
                                    { field: 'id', headerName: 'ID', width: 50 },
                                    { field: 'miles', headerName: 'Milhas', flex: 1, valueFormatter: ({value}) => {
                                        return Number(value).toLocaleString('pt-BR')
                                    }},
                                    { field: 'pax', headerName: 'CPFs', flex: 1 },
                                    {
                                        field: 'payment.status', headerName: 'Pagamento', flex: 1, 
                                        valueGetter: ({row}) => {
                                            return getPaymentStatusLabel(row.payment.status)
                                        },
                                        renderCell: ({ formattedValue, row }) => {
                                            return (
                                                <Chip color={getPaymentStatusColor(row.payment.status)} label={formattedValue} />
                                            )
                                        }
                                    },
                                    {
                                        field: 'confirmation', headerName: 'Status', flex: 1, 
                                        valueGetter: ({value}) => {
                                            return getOrderConfirmationLabel(value)
                                        },
                                        renderCell: ({ formattedValue, row }) => {
                                            return (
                                                <Chip color={getOrderConfirmationColor(row)} label={formattedValue} />
                                            )
                                        }
                                    },
                                    {
                                        field: 'actions', headerName: '', sortable: false, filterable: false, disableColumnMenu: true, renderCell: ({row}) => {
                                            return (
                                                <Button variant="outlined" href={`/emissoes/${row.id}`}>
                                                    <VisibilityIcon />
                                                </Button>
                                            )
                                        }
                                    }
                                ]}
                                rows={bid?.data?.orders || []}
                            />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>)}
        </Layout>
    )
}