import { useFormik } from "formik";
import { Grid, Button, Input, Typography, PhoneInput } from "../../components";
import {SignUpValidationSchema} from '@next-pontos/validations'
import useSWR from 'swr'
import { useContext, useEffect } from "react";
import { Link, Tooltip } from "@mui/material";
import AuthContext from "../../context/auth";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import TagManager from 'react-gtm-module'

export function Cadastro () { 
    const { signUp } = useContext(AuthContext)
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const invite_code = searchParams.get('invite_code')

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            cpf: '',
            phone_number: '',
            whatsapp: '',
            telegram: '',
            cep: '',
            address: '',
            address_number: '',
            neighborhood: '',
            complement: '',
            city: '',
            state: '',
            password: '',
            password_confirmation: '',
            invite_code
        },
        onSubmit: async (values) => {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'SignUp', 
                    name: values.name,
                    email: values.email,
                    phone_number: values.phone_number,
                    address_city: values.city,
                    address_state: values.state
                },
            })

            await signUp(values)
            navigate('/home')
        },
        validationSchema: SignUpValidationSchema,
        validateOnMount: true
    })

    const {setFieldValue} = formik

    const cleanCEP = formik.values.cep.replace(/\D/ig, '')

    const cepSearch = useSWR(`https://viacep.com.br/ws/${cleanCEP}/json/`, (...args) => { 
        if (cleanCEP.length === 8) {
            return fetch(...args).then(res => res.json())
        }

        return
    })

    useEffect(() => {
        if (cepSearch.data && !cepSearch.isLoading && !cepSearch.error) {
            setFieldValue('address', cepSearch.data.logradouro || '')
            setFieldValue('city', cepSearch.data.localidade || '')
            setFieldValue('state', cepSearch.data.uf || '')
            setFieldValue('neighborhood', cepSearch.data.bairro || '')
        }
    }, [cepSearch.data])
    
    return (
        <Grid container justifyContent="center" minHeight={"100vh"} alignItems="center" style={{
            backgroundColor: 'white',
            backgroundImage: 'url(/background.png)',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }}>
            <Grid item xs={12} sm={4}>
                    <form onSubmit={formik.handleSubmit}>
                    <Grid container padding={2} spacing={2} justifyContent="center" textAlign="center">
                        <img src="/logo.png" alt="Logo" style={{width: '50%'}}/>
                        <Grid item xs={12}>
                            <Input 
                                label="Nome Completo"
                                name="name"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                helperText={formik.touched.name && formik.errors.name}
                                error={!!formik.touched.name && !!formik.errors.name}
                                onBlur={formik.handleBlur}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}> 
                            <Input 
                                label="Email"
                                name="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                helperText={formik.touched.email && formik.errors.email}
                                error={!!formik.touched.email && !!formik.errors.email}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input 
                                label="CPF"
                                name="cpf"
                                onBlur={formik.handleBlur}
                                onChange={(event) => {
                                    formik.setFieldValue('cpf', event.target.value.replace(/\D/g, '')
                                    .replace(/(\d{3})(\d)/, '$1.$2')
                                    .replace(/(\d{3})(\d)/, '$1.$2')
                                    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
                                    .replace(/(-\d{2})\d+?$/, '$1'))
                                }}
                                value={formik.values.cpf}
                                helperText={formik.touched.cpf && formik.errors.cpf}
                                error={!!formik.touched.cpf && !!formik.errors.cpf}
                            /> 
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <PhoneInput
                                label="Telefone"
                                inputProps={{
                                    name: 'phone_number'
                                }}
                                onChange={value => {
                                    formik.setFieldValue('phone_number', value, true)
                                }}
                                value={formik.values.phone_number}
                                // helperText={formik.touched.phone_number && formik.errors.phone_number}
                                onBlur={formik.handleBlur}
                                error={!!formik.touched.phone_number && !!formik.errors.phone_number}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <PhoneInput
                                label="WhatsApp"
                                inputProps={{
                                    name: 'whatsapp'
                                }}
                                onChange={value => {
                                    formik.setFieldValue('whatsapp', value, true)
                                }}
                                value={formik.values.whatsapp}
                                // helperText={formik.touched.whatsapp && formik.errors.phone_number}
                                onBlur={formik.handleBlur}
                                error={!!formik.touched.whatsapp && !!formik.errors.whatsapp}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Input 
                                label="Telegram"
                                name="telegram"
                                onChange={(event) => {
                                    const value = event.target.value.replace('@', '')
                                    
                                    formik.setFieldValue('telegram', value.length > 0 ? `@${value}` : '')  
                                }}
                                value={formik.values.telegram}
                                helperText={formik.touched.telegram && formik.errors.telegram}
                                onBlur={formik.handleBlur}
                                error={!!formik.touched.telegram && !!formik.errors.telegram}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Input 
                                label="CEP"
                                name="cep"
                                onChange={(event) => {
                                    formik.setFieldValue('cep', event.target.value.replace(/\D/g,'').replace(/(\d{5})(\d)/,'$1-$2')
                                  )
                                }}
                                value={formik.values.cep}
                                helperText={formik.touched.cep && formik.errors.cep}
                                onBlur={formik.handleBlur}
                                error={!!formik.touched.cep && !!formik.errors.cep}
                            />
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Input 
                                label="Logradouro"
                                name="address"
                                onChange={formik.handleChange}
                                value={formik.values.address}
                                helperText={formik.touched.address && formik.errors.address}
                                onBlur={formik.handleBlur}
                                error={!!formik.touched.address && !!formik.errors.address}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Input 
                                label="Número"
                                name="address_number"
                                onChange={formik.handleChange}
                                value={formik.values.address_number}
                                helperText={formik.touched.address_number && formik.errors.address_number}
                                onBlur={formik.handleBlur}
                                error={!!formik.touched.address_number && !!formik.errors.address_number}
                            />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <Input 
                                label="Complemento"
                                name="complement"
                                onChange={formik.handleChange}
                                value={formik.values.complement}
                                helperText={formik.touched.complement && formik.errors.complement}
                                onBlur={formik.handleBlur}
                                error={!!formik.touched.complement && !!formik.errors.complement}
                            />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            <Input 
                                label="Bairro"
                                name="neighborhood"
                                onChange={formik.handleChange}
                                value={formik.values.neighborhood}
                                helperText={formik.touched.neighborhood && formik.errors.neighborhood}
                                onBlur={formik.handleBlur}
                                error={!!formik.touched.neighborhood && !!formik.errors.neighborhood}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Input 
                                label="Cidade"
                                name="city"
                                onChange={formik.handleChange}
                                value={formik.values.city}
                                helperText={formik.touched.city && formik.errors.city}
                                onBlur={formik.handleBlur}
                                error={!!formik.touched.city && !!formik.errors.city}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Input 
                                label="Estado"
                                name="state"
                                onChange={formik.handleChange}
                                value={formik.values.state}
                                helperText={formik.touched.state && formik.errors.state}
                                onBlur={formik.handleBlur}
                                error={!!formik.touched.state && !!formik.errors.state}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Tooltip arrow title="Sua senha deve conter no mínimo 8 carateres, uma letra minúscula, uma letra maiúscula e um número.">
                                <Input 
                                    label="Senha"
                                    type="password"
                                    name="password"
                                    onChange={formik.handleChange}
                                    helperText={formik.touched.password && formik.errors.password}
                                    onBlur={formik.handleBlur}
                                    error={!!formik.touched.password && !!formik.errors.password}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input 
                                label="Confirmar Senha"
                                type="password"
                                name="password_confirmation"
                                onChange={formik.handleChange}
                                helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
                                onBlur={formik.handleBlur}
                                error={!!formik.touched.password_confirmation && !!formik.errors.password_confirmation}
                            />
                        </Grid>
                        <Grid item marginTop={2} xs={12}>
                            <Typography fontStyle="italic" fontSize={12}>
                                Ao clicar em "Cadastrar" você está concordando com a nossa <a href="politica-de-privacidade.pdf" target="_blank">política de privacidade.</a>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button disabled={!formik.isValid} type="submit" id="signup">Cadastrar</Button>
                        </Grid>
                        <Grid item marginTop={2} xs={12}>
                            <Typography>
                                Já possui cadastro? <Link href='/'>Clique aqui!</Link>
                            </Typography>
                        </Grid>
                    </Grid>
                    </form>
            </Grid>
        </Grid>
    )
}