import { Button, Grid, Menu, Layout, Paper, Table, ButtonGroup, Loader, Switch } from "../../components";
import moment from "moment";
import { CARD_BRAND_OPTIONS } from "../../constants";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useAxios } from '../../hooks'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { GridActionsCellItem } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

export function Cards () {
    const [cards, refetchCards] = useAxios(`/card`)

    const [updatedCard, updateCard] = useAxios({}, {
        manual: true
    })

    const [deletedCard, deleteCard] = useAxios({
        url: '/card',
        method: 'DELETE'
    }, {
        manual: true
    })

    return (
        <Layout>
            <Grid container justifyContent="center" minHeight={"calc(100vh - 64px)"} padding={3} >
                {cards.loading || deletedCard.loading || updatedCard.loading ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) :(<Grid item xs={12} sm={10}>
                    <Grid container paddingBottom={2} justifyContent="flex-start">
                        <Grid item xs={4} justifyContent="flex-start">
                            <Button href="/cartoes/novo">
                                Novo Cartão
                            </Button>
                        </Grid>
                    </Grid>
                    <Paper elevation={1}>
                    <Table 
                        columns={[
                            { field: 'id', headerName: 'ID', width: 50 },
                            { field: 'created_at', headerName: 'Data', width: 200, valueFormatter: ({value}) => {
                                return moment(value).format('DD/MM/YYYY HH:mm:ss')
                            } },
                            { field: 'bank', headerName: 'Banco', flex: 1 },
                            { field: 'alias', headerName: 'Apelido', flex: 1 },
                            { field: 'brand', headerName: 'Bandeira', flex: 1 },
                            { field: 'last_four_digits', headerName: 'Número', flex: 1, valueFormatter: ({value}) => {
                                return `**** **** **** ${value}`
                            }},
                            { field: 'active', headerName: 'Ativo', width: 70, renderCell: ({value, id}) => {
                                
                                return (
                                    <Switch 
                                        checked={value}
                                        onChange={async () => {
                                            const {status} = await updateCard({
                                                url: `/card/${id}/active`,
                                                method: 'PUT',
                                                data: {
                                                    active: !value
                                                }
                                            })

                                            if (status >= 200) {
                                                enqueueSnackbar('Cartão atualizado com sucesso!', {
                                                    variant: 'success'
                                                })

                                                refetchCards()
                                            }
                                        }}
                                    />
                                )
                            }},
                            {
                                field: 'actions',
                                type: 'actions',
                                headerName: '',
                                width: 100,
                                cellClassName: 'actions',
                                getActions: ({ id }) => {
                                  return [
                                    <GridActionsCellItem
                                        icon={<EditIcon />}
                                        label="Editar"
                                        onClick={() => {
                                            window.location.replace(`/cartoes/${id}`)
                                        }}
                                        color="inherit"
                                    />,
                                    <GridActionsCellItem
                                        icon={<DeleteIcon />}
                                        label="Deletar"
                                        onClick={async () => {
                                            const {status} = await deleteCard({
                                                url: `/card/${id}`
                                            })

                                            if (status === 204) {
                                                enqueueSnackbar('Cartão deletado com sucesso!', {
                                                    variant: 'success'
                                                })
                                            }

                                            await refetchCards()
                                        }}
                                        color="error"
                                    />,
                                  ];
                                },
                            },
                        ]}
                        rows={cards?.data?.map((item: any) => ({
                            ...item,
                            brand: CARD_BRAND_OPTIONS.find(brand => brand.value === item.brand)?.label,
                        })) || []}
                    />
                    </Paper>
                </Grid>)}
            </Grid>
        </Layout>
    )
}