import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { useAxios } from "../../hooks";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Fragment, useState } from "react";
import { Modal } from '../Modal';
import { Grid } from '../Grid';
import { Button } from '../Button';
import { Typography } from '../Typography';
import { Loader } from '../Loader';

interface CreditCardModalProps {
    open: boolean
    onClose: () => void
    cardId: number
}

export const CreditCardModal = (props: CreditCardModalProps) => {
    const [card] = useAxios(`/card/${props.cardId}/payload`)
    const [side, setSide] = useState<'front' | 'back'>('front')

    return (
        <Modal
            title="Cartão de Crédito"
            open={props.open}
            onClose={props.onClose}
            bodyCustomCss={{
                width: 400
            }}
        >
            <Grid container spacing={2}>
                {card.loading ? (
                    <Grid item xs={12} textAlign="center">
                        <Loader />
                    </Grid>
                ) : (
                    <Fragment>
                        {
                            card?.data?.number ? (
                                <Fragment>
                                    <Grid item xs={12}>
                                        <Cards
                                            number={card.data.number}
                                            expiry={card.data.expiry}
                                            cvc={card.data.cvv}
                                            name={card.data.holder_name}
                                            focused={side === 'front' ? undefined : 'cvc'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} textAlign="center">
                                        <Button variant="text"
                                            onClick={() => {
                                                setSide(state => state === 'front' ? 'back' : 'front')
                                            }}
                                        >
                                            <VisibilityIcon style={{marginRight: 5}} />
                                            {side === 'front' ? 'Ver CVV' : 'Ver Frente'}
                                        </Button>
                                    </Grid>
                                </Fragment>
                            ) : (
                                <Grid item xs={12}>
                                    <Typography fontStyle="italic" textAlign="center">
                                        Dados do cartão incompletos. Entre em contato com o administrador do sistema.
                                    </Typography>
                                </Grid>
                            )
                        }
                    </Fragment>
                )}
            </Grid>
        </Modal>
    )
}