import { Alert, Button, FileUploader, Grid, Layout, Loader, Radio, Typography } from "../../../components";
import { Paper } from "../../../components";
import { useFormik } from "formik";
import { UpdatePaymentValidationSchema } from "@next-pontos/validations";
import useSWR from 'swr'
import { Chip, FormControl, FormControlLabel, FormLabel, Link, RadioGroup, Tooltip } from "@mui/material";
import { formatCPF, formatDate, formatDateTime, formatMoney, getBidStatusLabel, getOrderConfirmationColor, getOrderConfirmationLabel, getPixTypeLabel } from "../../../utils";
import { useParams } from "react-router-dom";
import API from "../../../API";
import { useAPI, useAxios, useToggle } from "../../../hooks";
import currency from "currency.js";
import { useTheme } from "@mui/material";
import { Fragment, useEffect, useMemo, useState } from "react";
import { CARD_BRAND_OPTIONS, COMPANY_OPTIONS } from "../../../constants";
import { QrCodePix } from 'qrcode-pix';
import { enqueueSnackbar } from "notistack";
import { APP_NAME } from "@next-pontos/settings";

export function Payment () { 
    const params = useParams()
    const theme = useTheme()
    const [isLoadingPIXQRCode, isLoadingPIXQRCodeControls] = useToggle(false)
    const [qrPix, setQrPix] = useState('')
    const [pixCode, setPixCode] = useState('')
    
    const [payment, refetchPayment] = useAxios(`/payment/${params.id}`)
    
    const [updatedPayment, updatePayment] = useAxios({
        url: `/payment/${params.id}`,
        method: 'POST'
    }, {
        manual: true
    })

    const [deletedBankDetails, deleteBankDetails] = useAxios({
        url: `/user/${payment?.data?.order?.bid?.user?.id}/bank-details`, 
        method: 'DELETE'
    }, {
        manual: true
    })

    const formik = useFormik({
        initialValues: {
            // receipt_file: '',
            status: '',
        },
        onSubmit: async (values) => {
            await updatePayment({
                data: values
            })
            
            window.history.back()
        },
        initialStatus: 'draft',
        validateOnMount: true,
        validationSchema: UpdatePaymentValidationSchema
    })

    useEffect(() => {
        if (payment.data) {
            formik.resetForm({
                values: {
                    status: payment.data?.status
                },
                status: 'initiated',
                touched: {}
            })
        }
    }, [payment.data])

    const orderProfit = useMemo(() => {
        return currency(payment?.data?.order?.sell_price).subtract(payment?.data?.order?.total_cost).value
    }, [payment?.data?.order])

    useEffect(() => {
        (async () => {
            if (payment?.data?.order?.bid?.user?.pix_key) {
                isLoadingPIXQRCodeControls.setTrue()

                let pix_key: string = ['EMAIL', 'RANDOM_KEY'].includes(payment?.data?.order?.bid?.user?.pix_type) ? payment?.data?.order?.bid?.user?.pix_key : payment?.data?.order?.bid?.user?.pix_key.replace(/\D/ig, '')

                if (payment?.data?.order?.bid?.user?.pix_type === 'PHONE_NUMBER' && pix_key.startsWith('55')) {
                    pix_key = `+${pix_key}`
                }

                const qrCodePix = QrCodePix({
                    version: '01',
                    key: pix_key,
                    name: payment?.data?.order?.bid?.user?.name,
                    city: payment?.data?.order?.bid?.user?.address_city,
                    transactionId: `${payment?.data?.id}`,
                    message: `${APP_NAME}: ${payment?.data?.id}`,
                    cep: payment?.data?.order?.bid?.user?.cep,
                    value: currency(payment?.data?.amount, {precision: 2}).value,
                })

                const qrCode = await qrCodePix.base64()
                
                setQrPix(qrCode)
                setPixCode(qrCodePix.payload())

                isLoadingPIXQRCodeControls.setFalse()
            }
        })()
    }, [isLoadingPIXQRCodeControls, payment.data])

    return (
        <Layout>
            <Grid container justifyContent="center" gap={4} minHeight={"calc(100vh - 64px)"} paddingTop={5}>
                {updatedPayment.loading || payment.loading || deletedBankDetails.loading ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) :(<Fragment>
                <Grid item xs={12} sm={4}>
                    {payment?.data?.order?.confirmation === 'CANCELLED' && (
                    <Grid item xs={12} marginBottom={4}><Alert color="error" icon={false}>
                        <strong>Emissão Cancelada!</strong>
                        <br />
                        Sua emissão está cancelada, então o pagamento não pode ter o status alterado.
                    </Alert>
                    </Grid>)}
                    <Paper elevation={1}>
                    <form onSubmit={formik.handleSubmit}>
                    <Grid container padding={2} spacing={2} justifyContent="center">
                        <Grid item xs={12} marginBottom={2}>
                            <Typography textAlign={"center"} variant="h5">Registrar Pagamento</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FileUploader 
                                disabled={payment?.data?.order?.confirmation === 'CANCELLED'}
                                value={null} 
                                onChange={() => {}} 
                                label="Comprovante"
                                inputProps={{
                                    accept: ['image/*', '.pdf']
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}> 
                            <FormControl>
                                <FormLabel>Status:</FormLabel>
                                <RadioGroup 
                                    value={formik.values.status}
                                onChange={event => {
                                    formik.setFieldValue('status', event.target.value, true)
                                }}>
                                    <FormControlLabel disabled={payment?.data?.order?.confirmation === 'CANCELLED' || !payment?.data?.order?.bid?.user?.pix_type} value="PAID" control={<Radio />} label="Pago" />
                                    <FormControlLabel disabled={payment?.data?.order?.confirmation === 'CANCELLED' || !payment?.data?.order?.bid?.user?.pix_type} value="CANCELLED" control={<Radio />} label="Cancelado" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item marginTop={2}>
                            <Button disabled={!formik.isValid || payment?.data?.order?.confirmation === 'CANCELLED' || !payment?.data?.order?.bid?.user?.pix_type} type="submit">Registrar</Button>
                        </Grid>
                    </Grid>
                    </form>
                    </Paper>
                    {payment?.data?.order?.confirmation !== 'CANCELLED' && payment?.data?.order?.bid?.user?.pix_key && (
                    <Paper elevation={1} style={{ marginTop: 30 }}>
                        <Grid container padding={2} spacing={2}>
                            <Grid item xs={12} marginBottom={2}>
                                <Typography textAlign={"center"} variant="h5">PIX</Typography>
                            </Grid>
                            <Grid item xs={12} textAlign="center">
                                <img src={qrPix} alt="QR Code PIX" width="60%"/>
                            </Grid>
                            <Grid item xs={12} textAlign={"center"} style={{
                                cursor: 'pointer'
                            }} onClick={() => {
                                navigator.clipboard.writeText(pixCode)

                                enqueueSnackbar('Código PIX copiado com sucesso!', {variant: 'success'})
                            }}>
                                <Typography variant="caption">{pixCode}</Typography>
                            </Grid>
                        </Grid>
                    </Paper>)}
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Paper elevation={1} >
                        <Grid container padding={2} spacing={2}>
                            <Grid item xs={12} marginBottom={2}>
                                <Typography textAlign={"center"} variant="h5">Dados Bancários</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Fornecedor:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography textAlign="end">
                                            {payment?.data?.order?.bid?.user?.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Tipo de PIX:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {!payment?.data?.order?.bid?.user?.pix_type ? (
                                            <Typography textAlign="end" fontStyle="italic" color="red">
                                                Pendente
                                            </Typography>
                                        ) : (<Typography textAlign="end">
                                            {getPixTypeLabel(payment?.data?.order?.bid?.user?.pix_type)}
                                        </Typography>)}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Chave PIX:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {!payment?.data?.order?.bid?.user?.pix_type ? (
                                            <Typography textAlign="end" fontStyle="italic" color="red">
                                                Pendente
                                            </Typography>
                                        ) : (<Typography textAlign="end">
                                            {
                                            payment?.data?.order?.bid?.user?.pix_type === 'CPF' 
                                                ? formatCPF(payment?.data?.order?.bid?.user?.pix_key)
                                                : payment?.data?.order?.bid?.user?.pix_key
                                            }
                                        </Typography>)}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Valor:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography textAlign="end" fontWeight="bold">
                                            {formatMoney(payment?.data?.amount)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {payment.data.status !== 'PAID' && payment?.data?.order?.bid?.user?.pix_type && payment?.data?.order?.bid?.user?.pix_key ? (<Grid item xs={12} textAlign="center">
                                <Tooltip
                                    title="Ao remover os dados bancários o usuário deverá inserir uma nova chave PIX para receber os pagamentos."
                                >
                                    <div>
                                        <Button color="error" onClick={async () => {
                                            await deleteBankDetails()
                                            refetchPayment()
                                        }}>
                                            Remover Dados Bancários
                                        </Button>
                                    </div>
                                </Tooltip>
                            </Grid>) : null}
                        </Grid>
                    </Paper>
                    <Paper elevation={1} style={{ marginTop: 30 }}>
                        <Grid container padding={2} spacing={2}>
                            <Grid item xs={12} marginBottom={2}>
                                <Typography textAlign={"center"} variant="h5">Dados da Emissão</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            ID:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="end">
                                        <Link href={`/emissoes/${payment?.data?.order?.id}`}>
                                            # {payment?.data?.order?.id}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Data da Emissão:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="end">
                                        <Typography textAlign="end">
                                            {formatDateTime(payment?.data?.order?.created_at)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Data do Voo:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="end">
                                        <Typography textAlign="end">
                                            {formatDate(payment?.data?.order?.flight_date)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Localizador:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="end">
                                        <Typography textAlign="end">
                                            {payment?.data?.order?.reservation_code}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Companhia:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="end">
                                        <Typography textAlign="end">
                                            {payment?.data?.order?.airline}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Programa:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="end">
                                        <Typography textAlign="end">
                                            {COMPANY_OPTIONS.find(item => item.value === payment?.data?.order?.bid?.company)?.label}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Status:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="end">
                                            <Chip color={getOrderConfirmationColor(payment?.data?.order)} label={getOrderConfirmationLabel(payment?.data?.order?.confirmation)} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Milhas:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="end">
                                        <Typography textAlign="end">
                                            {Number(payment?.data?.order?.miles).toLocaleString('pt-BR')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Valor do Milheiro:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="end">
                                        <Typography textAlign="end">
                                            {formatMoney(payment?.data?.order?.bid?.price_per_k)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Cartão:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="end">
                                        <Typography textAlign="end">
                                            {`${CARD_BRAND_OPTIONS.find(item => item.value === payment?.data?.order?.card?.brand)?.label} **** ${payment?.data?.order?.card?.last_four_digits}`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Taxas:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="end">
                                        <Typography textAlign="end">
                                            {formatMoney(payment?.data?.order?.fees)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Custos Adicionais:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="end">
                                        <Typography textAlign="end">
                                            {formatMoney(payment?.data?.order?.additional_cost)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Custo Total:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="end">
                                        <Typography textAlign="end">
                                            {formatMoney(payment?.data?.order?.total_cost)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Valor de Venda:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="end">
                                        <Typography textAlign="end">
                                            {formatMoney(payment?.data?.order?.sell_price)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Lucro:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="end">
                                        <Typography textAlign="end" color={orderProfit > 0 ? theme.palette.success.main : theme.palette.error.main} fontWeight="bold">
                                            {formatMoney(orderProfit)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                </Fragment>)}
            </Grid>
        </Layout>
    )
}