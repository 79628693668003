import {Fragment, useContext} from 'react'
import { Grid, Loader, Paper, Table } from "../../../components"
import AuthContext from '../../../context/auth'
import { useAxios } from '../../../hooks'
import moment from 'moment'
import currency from 'currency.js'
import { COMPANY_OPTIONS } from '../../../constants'
import { getBidStatusColor, getBidStatusLabel } from '../../../utils'
import { Chip } from '@mui/material'

export const Bids = () => {
    const { me } = useContext(AuthContext)

    const [userReferrals] = useAxios(`/user-referral/bids?inviter_id=${me?.id.toString()}`)
    
    return (
        <Grid container justifyContent="center" paddingTop={1} gap={3} >
            {userReferrals.loading ? (
                <div style={{
                    display: 'flex',
                    flex: '1',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Loader />
                </div>
            ) :(
            <Fragment> 
                <Grid item xs={12}>
                    <Paper elevation={1}>
                    <Table 
                        columns={[
                            { field: 'id', type: 'string', headerName: 'ID', width: 100 },
                            { field: 'created_at', type: 'dateTime', headerName: 'Data', flex: 1, valueFormatter: ({value}) => {
                                return moment(value).format('DD/MM/YYYY HH:mm:ss')
                            } },
                            { field: 'user', type: 'string', headerName: 'Usuário', flex: 1, valueGetter: ({value}) => value.name },
                            { field: 'company', type: 'string', headerName: 'Companhia', flex: 1, valueGetter: ({value}) =>  COMPANY_OPTIONS.find(item => item.value === value)?.label },
                            { field: 'amount', type: 'number', headerName: 'Milhas', flex: 1, valueFormatter: ({value}) => {
                                return Number(value).toLocaleString('pt-BR')
                            }},
                            { field: 'status', type: 'singleSelect', valueOptions: ['Pendente', 'Aceita', 'Recusada', 'Aguardando Emissão', 'Em Emissão', 'Finalizada'], headerName: 'Status', flex: 1, valueGetter: ({value}) => getBidStatusLabel(value), renderCell: ({value, row}) => <Chip color={getBidStatusColor(row.status)} label={value} /> },
                        ]}
                        rows={userReferrals.data || []}
                    />
                    </Paper>
                </Grid>
            </Fragment>
            )}
        </Grid>
    )
}