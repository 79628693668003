import { Box, Link, useMediaQuery, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Button, Grid, Paper, Typography } from '../../components';
import { useMemo } from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import './index.css'
import { APP_CONTACTS, APP_NAME } from '@next-pontos/settings';
import { Telegram } from '@mui/icons-material';

export const LPOrder = () => {
    const theme = useTheme()
    const isSM = useMediaQuery(theme.breakpoints.up('sm'));
    const isMD = useMediaQuery(theme.breakpoints.up('md'));
    const isLG = useMediaQuery(theme.breakpoints.up('lg'));
    const isXL = useMediaQuery(theme.breakpoints.up('xl'));

    const containerWidth = useMemo(() => {
        if (isXL) {
            return '70%'
        }

        if (isLG) {
            return '80%'
        }

        if (isMD) {
            return '85%'
        }

        return '90%'
    }, [isMD, isLG, isXL])

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column'
        }}>
            <AppBar component="nav" sx={{
                backgroundColor: 'white',
                height: 64
            }}>
                <Toolbar
                    style={{
                        paddingLeft: 140,
                        paddingRight: 140
                    }}
                >
                    <div style={{
                        display: 'flex',
                        flex: 1
                    }}>
                        <img src='/logo.png' alt="Logo" style={{ width: 110 }} />
                    </div> 
                    {isSM && (<div style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'flex-end'
                    }}>
                        <Button href='/'>
                            Entre ou cadastre-se
                        </Button>
                    </div>)}
                </Toolbar>
            </AppBar>
            <div
                style={{
                    display: 'flex',
                    minHeight: !isSM ? undefined : '100vh',
                    width: '100%',
                    background: theme.palette.primary.main,
                    marginTop: 64,
                    paddingTop: 64,
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingBottom: 64
                }}
            >
                <Grid container width={containerWidth} spacing={2}> 
                    <Grid item xs={12} sm={5}>
                        <Grid container spacing={4} height='100%'>
                            <Grid item xs={12}>
                                <Typography variant='h2' fontWeight='bold' color="white" textAlign={!isSM ? "center" : undefined}>
                                Emita Suas Passagens com Milhas Sem Precisar Ter Milhas!
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='subtitle1' color="white">
                                Viajar ficou mais fácil! Na {APP_NAME}, você pode garantir suas passagens aéreas com milhas, mesmo que ainda não as tenha. Fale com um de nossos especialistas via WhatsApp ou Telegram, e nós cuidamos de todo o processo para você!
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={0} sm={2} />
                    <Grid item xs={12} sm={5}>
                        <Paper elevation={1} sx={{
                            borderRadius: 5
                        }}>
                            <Grid container padding={3}>
                                <Grid item xs={12}>
                                    <Typography variant='h6' fontWeight='bold' color={theme.palette.primary.main}>
                                        Pronto para emitir sua passagem? 
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} mb={5} mt={1}>
                                    <Typography variant='subtitle2' color={theme.palette.primary.main}>
                                    Fale conosco!
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} textAlign={'center'}>
                                    <Button
                                        startIcon={<WhatsAppIcon />}
                                        onClick={() => 
                                            window.open(`https://wa.me/${APP_CONTACTS.whatsapp}`, '_blank')
                                        }
                                    >
                                        WhatsApp
                                    </Button>
                                </Grid>
                                <Grid item xs={6} textAlign={'center'}>
                                    <Button
                                        startIcon={<Telegram />}
                                        onClick={() => 
                                            window.open(`https://t.me/${APP_CONTACTS.telegram.replace('@', '')}`, '_blank')
                                        }
                                    >
                                        Telegram
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            {process.env.REACT_APP_CLIENT === 'NEXT' && (<div
                style={{
                    display: 'flex',
                    width: '100%',
                    background: 'white',
                    paddingTop: 10,
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingBottom: 10
                }}
            >
                <Grid container width={containerWidth} textAlign="left" display="flex" justifyContent="center" alignItems="center" gap={1}> 
                    <Grid item xs={12} textAlign="center">
                        <Typography fontWeight="bold" fontSize={14} color={theme.palette.primary.main}>Quem é a {APP_NAME}?</Typography>
                    </Grid>
                    <Grid item xs={12} color={theme.palette.primary.main} textAlign={"center"}>
                        <Typography fontSize={14}>Next é uma empresa fundada por <strong>Erik Galdino</strong>, que atua no mercado de compra e venda de milhas desde 2016, que também atua como mentor de agências de viagem e negócios ligados a milhas aéreas desde 2018. <Link href="https://www.instagram.com/erikgaldino" color="rgb(255, 255, 255)">@erikgaldino</Link></Typography>
                    </Grid>
                </Grid>
            </div>)}
        </Box>
    )
}