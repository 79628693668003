import { Button, Grid, Menu, Layout, Paper, Table, Alert, ButtonGroup, Loader } from "../../components";
import useSWR from 'swr'
import API from "../../API";
import moment from "moment";
import { COMPANY_OPTIONS } from "../../constants";
import currency from 'currency.js'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useAPI, useAxios } from '../../hooks'
import { Fragment, useContext } from "react";
import AuthContext from "../../context/auth";
import { AlertTitle, Box, Chip, Tab, Tabs } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getBidStatusColor, getBidStatusLabel } from "../../utils";
import { Referrals } from "./Referrals";
import { MilesNegotiatedPerCompany } from "./MilesNegotiatedPerCompany";
import { MilesNegotiatedPerUser } from "./MilesNegotiatedPerUser";
import { Bids } from "./Bids";
import { Users } from "./Users";
import { useTabs } from "@mui/base";

function a11yProps(index: number) {
    return {
      id: `banking-tab-${index}`,
      'aria-controls': `banking-tabpanel-${index}`,
    };
}    

export function MyReferrals () {
    const { me } = useContext(AuthContext)
    const tabs = useTabs({
        defaultValue: 0
    })

    const [userReferrals] = useAxios(`/user-referral?inviter_id=${me?.id.toString()}`)
    
    return (
        <Layout>
            <Grid container justifyContent="center" padding={3} overflow="scroll">
                {userReferrals.loading ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) : (
                    <Fragment>
                        <Grid item xs={11} sm={11} height={`40vh`}>
                            <Grid container height={"100%"} spacing={1}>
                                <Grid item xs={4} height={"100%"}>
                                    <Paper elevation={1} sx={{
                                        height: '100%',
                                        padding: 2,
                                    }}>
                                        <Referrals />
                                    </Paper>
                                </Grid>
                                <Grid item xs={4} height={"100%"}>
                                    <Paper elevation={1} sx={{
                                        height: '100%',
                                        padding: 2,
                                    }}>
                                        <MilesNegotiatedPerCompany />
                                    </Paper>
                                </Grid>
                                <Grid item xs={4} height={"100%"}>
                                    <Paper elevation={1} sx={{
                                        height: '100%',
                                        padding: 2,
                                    }}>
                                        <MilesNegotiatedPerUser />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={11} paddingTop={2}>
                            <Box sx={{ width: '100%' }}>
                                <Paper>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={tabs.contextValue.value} onChange={tabs.contextValue.onSelected} >
                                            <Tab label="Ofertas" {...a11yProps(0)} />
                                            <Tab label="Usuários" {...a11yProps(1)} />
                                        </Tabs>
                                    </Box>
                                </Paper>
                                {tabs.contextValue.value === 0 && (
                                    <Bids />
                                )}
                                {tabs.contextValue.value === 1 && (
                                    <Users />
                                )}
                            </Box>
                        </Grid>
                    </Fragment>
                )}
            </Grid>
        </Layout>
    )
}