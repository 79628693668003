import currency from "currency.js"
import { useFormik } from "formik"
import moment from "moment"
import { useEffect, useMemo } from "react"
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts"
import { DateRangePicker, Paper, Typography, MultipleAutocomplete, MultipleAutocompleteOption } from "../../components"
import { COMPANY_OPTIONS } from "../../constants"
import { useAxios } from "../../hooks"

export const Orders = () => {
    const [dashboard, refetchDashboard] = useAxios('/dashboard/orders', {
        manual: true
    })

    const searchFormik = useFormik({
        initialValues: {
            from: moment().subtract(1, 'month').toDate(),
            to: moment().toDate(),
            user_id: [] as Array<string | number>
        },
        onSubmit: values => {}
    })

    const values = searchFormik.values

    useEffect(() => {
        refetchDashboard({
            params: {
                from: values.from,
                to: values.to,
                user_id: values.user_id
            }
        })
    }, [values])

    const [users] = useAxios('/user') 

    const userOptions = useMemo(() => {
        return users?.data?.filter(({role}: {role: 'ADMIN' | 'SELLER'}) => role === 'ADMIN' || role === 'SELLER').map((item: {name: string, id: number}) => ({
            value: item.id,
            label: item.name
        })) || []
    }, [users.data])
    
    return (
        <Paper elevation={1} sx={{
            height: '100%',
            padding: 2
        }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 10,
                    alignItems: 'center'
                }}
            >
                <Typography component="h2" variant="h6" color="primary" fontSize={20} flex={1}>
                    Emissões Realizadas
                </Typography>
                <div 
                    style={{
                        display: 'flex',
                        flex: 2,
                        justifyContent: 'space-between'
                    }}
                >   
                    <MultipleAutocomplete
                        variant="outlined"
                        label="Emissor"
                        onChange={(value) => {
                            searchFormik.setFieldValue('user_id', (value as MultipleAutocompleteOption[])?.map(item => item.value))
                        }}
                        options={userOptions}
                        value={userOptions.filter((item: MultipleAutocompleteOption) => searchFormik.values.user_id.includes(item.value))}
                        style={{
                            marginRight: 5,
                            width: '60%',
                        }}
                    />
                    <DateRangePicker
                        startDate={searchFormik.values.from}
                        endDate={searchFormik.values.to}
                        onChange={(value) => {
                            searchFormik.setFieldValue('from', value.startDate)
                            searchFormik.setFieldValue('from', value.startDate)
                        }}
                    />
                </div>
            </div>
            <div style={{
                height: '100%'
            }}>
            <ResponsiveContainer
                height={'80%'}
            >
                {!dashboard.loading && dashboard?.data ? (
                    <PieChart
                        margin={{
                            top: 16,
                            right: 16,
                            left: 16,
                            bottom: 16,
                        }}
                    >
                    <Pie
                        data={dashboard?.data || []} 
                        dataKey="amount" 
                        label={(payload) => {
                            
                            return `${COMPANY_OPTIONS.find(item => item.value === payload.company)?.label}: ${Number(payload.amount).toLocaleString('pt-BR')} / ${Number(payload?.miles).toLocaleString('pt-BR', {
                                notation: 'compact',
                                maximumFractionDigits: 3
                            })} (${currency(payload.percent).multiply(100).value}%)`
                        }}
                    >
                    {
                        dashboard?.data?.map((item: { company: string }) => {
                            return (
                                <Cell
                                    key={item.company} 
                                    fill={COMPANY_OPTIONS.find(company => company.value === item.company)?.color}
                                />
                            )
                        })
                    }
                    </Pie>
                    <Tooltip
                        formatter={(value: number, index) => {
                            return [
                                `${COMPANY_OPTIONS.find(company => company.value === dashboard?.data[index].company)?.label}: ${Number(value).toLocaleString('pt-BR')} / ${Number(dashboard?.data[index]?.miles).toLocaleString('pt-BR', {
                                    notation: 'compact',
                                    maximumFractionDigits: 3
                                })}`
                            ]
                        }}
                        labelFormatter={label => {
                            return COMPANY_OPTIONS.find(company => company.value === label.company)?.label
                        }}
                        labelStyle={{
                            fontWeight: 'bold'
                        }}
                    />
                    <Legend
                        formatter={value => {
                            return COMPANY_OPTIONS.find(company => company.value === dashboard?.data[value].company)?.label
                        }}
                        fontWeight="bold"
                    /> 
                  </PieChart>
                ) : ( <div />)}
            </ResponsiveContainer>
            </div>
        </Paper>
    )
}