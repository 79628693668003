import { Axios } from "axios"
import { useCallback, useState } from "react"
import API from "../API"

export const useAPI = () => {
    const [isLoading, setIsLoading] = useState(false)

    const post: Axios['post'] = useCallback(async (...params) => {
        // setIsLoading(true)

        return API.post(...params)

    }, [])

    const get: Axios['get'] = useCallback(async (...params) => {
        return API.get(...params)
    }, [])

    const deleteAction: Axios['delete'] = useCallback(async (...params) => {
        return API.delete(...params)
    }, [])

    const put: Axios['put'] = useCallback(async (...params) => {
        return API.put(...params)
    }, [])

    return {
        isLoading,
        post,
        get,
        delete: deleteAction,
        put
    }
}