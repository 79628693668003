import { Button, Grid, Layout, Paper, Table, Loader } from "../../components";
import moment from "moment";
import { COMPANY_OPTIONS } from "../../constants";
import { useAxios } from '../../hooks'
import { useContext } from "react";
import AuthContext from "../../context/auth";
import { Chip, Link } from "@mui/material";
import { formatMoney, getPaymentStatusColor, getPaymentStatusLabel } from "../../utils";
import DownloadIcon from '@mui/icons-material/Download';

export function MyPayments () {
    const { me } = useContext(AuthContext)

    const [payments] = useAxios(`/payment?user_id=${me?.id.toString()}`)
    
    return (
        <Layout>
            <Grid container justifyContent="center" minHeight={"calc(100vh - 64px)"} padding={3} >
                {payments.loading ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) : (<Grid item xs={12} sm={10}>
                    <Paper elevation={1}>
                    <Table 
                        columns={[
                            { field: 'id', headerName: 'ID', width: 50 },
                            { field: 'created_at', headerName: 'Data', width: 200, valueFormatter: ({value}) => {
                                return moment(value).format('DD/MM/YYYY HH:mm:ss')
                            } },
                            { field: 'due_date', headerName: 'Vencimento', width: 200, valueFormatter: ({value}) => {
                                return moment(value).format('DD/MM/YYYY HH:mm:ss')
                            } },
                            { field: 'bid_id', headerName: 'Oferta', flex: 1, renderCell: ({row}) => {
                                return (
                                    <Link href={`/minhas-ofertas/${row.id}`}> 
                                        # {row.bid_id}
                                    </Link>
                                )
                            }},
                            { field: 'company', headerName: 'Programa', flex: 1 },
                            { field: 'used_miles', headerName: 'Milhas Utilizadas', flex: 1, valueFormatter: ({value}) => Number(value).toLocaleString('pt-BR')} ,
                            { field: 'amount', headerName: 'Valor', flex: 1, valueFormatter: ({value }) => {
                                return formatMoney(value)
                            }},
                            {
                                field: 'status', headerName: 'Status', flex: 1, 
                                valueFormatter: ({value}) => {
                                    return getPaymentStatusLabel(value)
                                },
                                renderCell: ({ formattedValue, value }) => {
                                    return (
                                        <Chip color={getPaymentStatusColor(value)} label={formattedValue} />
                                    )
                                }
                            },
                            // {
                            //     field: 'actions', headerName: '', sortable: false, filterable: false, disableColumnMenu: true, renderCell: ({row}) => {
                            //         return (
                            //             <Button disabled={row.status !== 'PAID'} variant="outlined">
                            //                 <DownloadIcon />
                            //             </Button>
                            //         )
                            //     }
                            // }
                        ]}
                        rows={(payments?.data || []).map((item: { order: { miles: number, bid: { id: number, company: string, user: { name: string }}}}) => ({
                            ...item,
                            used_miles: item.order.miles,
                            bid_id: item.order.bid.id,
                            company: COMPANY_OPTIONS.find(company => company.value === item.order.bid.company)?.label
                        }))}
                    />
                    </Paper>
                </Grid>)}
            </Grid>
        </Layout>
    )
}