import { useFormik } from "formik";
import { Grid, Button, Input, Typography } from "../../components";
import {SignInValidationSchema } from '@next-pontos/validations'
import { useContext } from "react";
import AuthContext from "../../context/auth";
import { useNavigate } from "react-router-dom";
import { Link } from "@mui/material";

export function Login () { 
    const { signIn } = useContext(AuthContext)
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            login: '',
            password: '',
        },
        onSubmit: async (values) => {
            await signIn(values)
            navigate('/home')
        },
        validationSchema: SignInValidationSchema,
        validateOnMount: true
    })
    
    return (
        <Grid container justifyContent="center" minHeight={"100vh"} alignItems="center" style={{
            backgroundColor: 'white',
            backgroundImage: 'url(/background.png)',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }}>
            <Grid item xs={12} sm={4}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container padding={2} spacing={2} justifyContent="center" textAlign={"center"}>
                        <img src="/logo.png" alt="Logo" style={{width: '50%'}}/>
                        <Grid item xs={12}> 
                            <Input 
                                label="Email ou CPF"
                                name="login"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.login}
                                helperText={formik.touched.login && formik.errors.login}
                                error={!!formik.touched.login && !!formik.errors.login}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input 
                                label="Senha"
                                type="password"
                                name="password"
                                onChange={formik.handleChange}
                                helperText={formik.touched.password && formik.errors.password}
                                onBlur={formik.handleBlur}
                                error={!!formik.touched.password && !!formik.errors.password}
                            />
                        </Grid>
                        <Grid item marginTop={2} xs={12}>
                            <Button disabled={!formik.isValid} type="submit">Entrar</Button>
                        </Grid>
                        <Grid item marginTop={2} xs={12}>
                            <Typography>
                                Esqueceu sua senha? <Link href='/esqueci-minha-senha'>Clique aqui!</Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                Ainda não tem cadastro? <Link href='/cadastro'>Clique aqui!</Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    )
}