import { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { SignInValidationSchema, SignUpValidationSchema } from '@next-pontos/validations'
import { useAxios } from "../hooks";
import { Loader } from "../components";

export interface User {
    id: number
    email: string
    name: string
    role: 'ADMIN' | 'USER' | 'SELLER' | 'AGENCY'
    pix_type: 'EMAIL' | 'PHONE_NUMBER' | 'CPF' | 'RANDOM_KEY' | null
    pix_key: string | null
    cpf: string
    cep: string
    address_city: string
    address_complement: string | null
    address_neighborhood: string
    address_number: string
    address_state: string
    address_street: string
    phone_number: string
    telegram: string
    whatsapp: string
    bids: Bid[]
    is_first_login: boolean
    invite_code?: string,
    user_module: {
        is_referral_enabled: boolean
    }
  }
  
export type Company = 'AZUL' | 'LATAM' | 'GOL' | 'TAP' | 'IBERIA' | 'AMERICAN_AIRLINES' | 'QATAR' | 'LIVELO' | 'ESFERA' | 'ITAU' | 'INTERLINE' | 'CAIXA'

export interface Bid {
    id: number
    company: Company
    status: 'ACCEPTED' | 'DENIED' | 'DONE' | 'PENDING'
}

interface AuthContextValues {
    signed: boolean,
    isLoading: boolean,
    signUp: (values: typeof SignUpValidationSchema.__outputType) => void
    signIn: (values: typeof SignInValidationSchema.__outputType) => void
    signOut: (callback: () => void) => void
    me: User | null
}

const AuthContext = createContext({} as AuthContextValues);

export const AuthContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [token, setToken] = useState('')

    const [me, refetchMe] = useAxios('/user/me')
    
    const [login, loginAPI] = useAxios({
        url: '/auth/signin',
        method: 'POST'
    }, {
        manual: true
    })

    const [register, registerAPI] = useAxios({
        url: '/auth/signup',
        method: 'POST'
    }, {
        manual: true
    })

    const signUp = useCallback(async (values: typeof SignUpValidationSchema.__outputType) => {
        const { data } = await registerAPI({
            data: values
        })
        storeToken(data.token)
    }, [registerAPI])
    
    const signIn = useCallback(async (values: typeof SignInValidationSchema.__outputType) => {
        const { data } = await loginAPI({
            data: values
        })
        storeToken(data.token)
    }, [loginAPI])

    const signOut = useCallback((callback: () => void) => {
        window?.localStorage?.removeItem('@next-pontos/token')

        setToken('')
        callback()
    }, [])

    const storeToken = (token: string) => {
        window?.localStorage?.setItem('@next-pontos/token', token)

        setToken(token)
    }

    useEffect(() => {
        const storedToken = window.localStorage.getItem('@next-pontos/token') || ''

        setToken(storedToken)
    }, [])

    useEffect(() => {
        if (token) {
            refetchMe()
        }
    }, [token])
    
    const isLoading = useMemo(() => {
        return login.loading || me.loading || register.loading
    }, [login.loading, me.loading, register.loading])

    if (isLoading) {
        return (
            <div style={{
                display: 'flex',
                flex: '1',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Loader />
            </div>
        )
    }

 return (
   <AuthContext.Provider value={{ 
        signed: Boolean(me.data) && Boolean(token), 
        isLoading: isLoading,
        signUp,
        signIn,
        signOut,
        me: me.data
    }}>
     {children}
   </AuthContext.Provider>
 );
};

export default AuthContext;