import { DateRange, DateRangePicker as MuiDateRangePicker,  } from "mui-daterange-picker";
import { Fragment, useCallback, useState } from "react";
import { useToggle } from "../../hooks";
import { formatDate } from "../../utils";
import { Input } from "../Input";
import { Modal } from "../Modal";
import ptLocale from 'date-fns/locale/pt-BR'
import './index.css'

interface DateRangePickerProps {
    startDate: Date
    endDate: Date
    onChange: (value: DateRange) => void
}

export const DateRangePicker = (props: DateRangePickerProps) => {
    const [startDate, setStartDate] = useState(props.startDate)
    const [endDate, setEndDate] = useState(props.endDate)
    const [open, controls] = useToggle(false)
    
    const handleChange = useCallback(() => {
        props.onChange({
            startDate,
            endDate
        })

        controls.setFalse()
    }, [props, startDate, endDate])

    return (
        <Fragment>
            <div style={{
                display: 'flex',
                flexDirection: 'row'
            }}>
            <Input 
                label="De"
                onClick={controls.setTrue}
                value={formatDate(startDate)}
                style={{
                    marginRight: 5
                }}
            />
            <Input 
                label="Até"
                onClick={controls.setTrue}
                value={formatDate(endDate)}
            />
            </div>
            <Modal
                open={open}
                bodyCustomCss={{
                    padding: 0
                }}
                onClose={controls.setFalse}
                onConfirm={handleChange}
                confirmLabel="Selecionar"
            >
        <MuiDateRangePicker
            open={open}
            toggle={controls.toggle}
            initialDateRange={{
                startDate,
                endDate
            }}
            closeOnClickOutside={false}
            wrapperClassName="date-range-picker"
            locale={ptLocale}
            onChange={(range) => {
                if (range.endDate && range.startDate) {
                    setEndDate(range.endDate)
                    setStartDate(range.startDate)
                }
            }}
        /></Modal>
        </Fragment>
    )
}