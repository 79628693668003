import { Button, Grid, Layout, Paper, Table, Loader, ButtonGroup, Menu } from "../../components";
import moment from "moment";
import { COMPANY_OPTIONS } from "../../constants";
import { useAPI, useAxios } from '../../hooks'
import { Fragment, useContext } from "react";
import AuthContext from "../../context/auth";
import { Chip, Link } from "@mui/material";
import { formatMoney, getPaymentStatusColor, getPaymentStatusLabel, getSupportTicketStatusColor, getSupportTicketStatusLabel } from "../../utils";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useNavigate } from "react-router-dom";

export function Agencies () {
    const navigate = useNavigate()
    const [agencies] = useAxios(`/user?role=AGENCY`)
    
    return (
        <Layout>
            <Grid container justifyContent="center" padding={3} spacing={2}>
                {agencies.loading ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) : (
                    <Fragment>
                <Grid item xs={12} sm={10}>
                    <Paper elevation={1}>
                    <Table 
                        columns={[
                            { field: 'id', headerName: 'ID', width: 50 },
                            { field: 'created_at', headerName: 'Criado em', width: 200, valueFormatter: ({value}) => {
                                return moment(value).format('DD/MM/YYYY HH:mm:ss')
                            } },
                            { field: 'name', headerName: 'Nome', flex: 1, renderCell: ({row, value}) => {
                                return (
                                    <Link href={`/usuarios/${row.id}`}>
                                        {value}
                                    </Link>
                                )
                            }},
                        ]}
                        rows={(agencies?.data || [])}
                    />
                    </Paper>
                </Grid>
                </Fragment>)}
            </Grid>
        </Layout>
    )
}