import {Fragment, useContext} from 'react'
import { Grid, Loader, Paper, Table } from "../../../components"
import AuthContext from '../../../context/auth'
import { useAxios } from '../../../hooks'
import moment from 'moment'
import currency from 'currency.js'
import { Link } from '@mui/material'

export const Users = () => {
    const {me} = useContext(AuthContext)

    const [userReferrals] = useAxios(`/user-referral`)

    return (
        <Grid container justifyContent="center" paddingTop={1} gap={3} >
            {userReferrals.loading ? (
                <div style={{
                    display: 'flex',
                    flex: '1',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Loader />
                </div>
            ) :(
            <Fragment> 
                <Grid item xs={12}>
                    <Paper elevation={1}>
                    <Table 
                        columns={[
                            { field: 'id', type: 'string', headerName: 'ID', width: 100, renderCell: ({value}) => (
                                <Link href={`/usuarios/${value}`}>
                                    {value}
                                </Link>
                            ) },
                            { field: 'created_at', type: 'dateTime', headerName: 'Data', flex: 1, valueFormatter: ({value}) => {
                                return moment(value).format('DD/MM/YYYY HH:mm:ss')
                            } },
                            { field: 'name', type: 'string', headerName: 'Name', flex: 1, renderCell: ({row}) => {
                                return (
                                    <Link href={`/usuarios/${row.id}`}>
                                        {row.name}
                                    </Link>
                                )
                            } },
                            { field: 'invited_by', type: 'string', headerName: 'Indicado por', flex: 1, valueGetter: ({row}) => row.inviter.name, renderCell: ({row}) => {
                                return (
                                    <Link href={`/usuarios/${row.inviter.id}`}>
                                        {row.inviter.name}
                                    </Link>
                                )
                            } },
                            { field: 'bids', type: 'number', headerName: 'Ofertas', flex: 1, valueGetter: ({value}) => value.length, valueFormatter: ({value}) => {
                                return Number(value).toLocaleString('pt-BR')
                            }},
                            { field: 'bid_miles', type: 'number', headerName: 'Milhas Ofertadas', flex: 1, valueGetter: ({row}) => row.bids.reduce((prev: number, curr: {amount: number}) => currency(prev).add(curr.amount).value, 0), valueFormatter: ({value}) => {
                                return Number(value).toLocaleString('pt-BR')
                            }},
                        ]}
                        rows={userReferrals.data || []}
                    />
                    </Paper>
                </Grid>
            </Fragment>
            )}
        </Grid>
    )
}