import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { useAxios } from "../../hooks";
import { Fragment, useEffect } from "react";
import { Modal } from '../Modal';
import { Grid } from '../Grid';
import { Loader } from '../Loader';
import { useFormik } from 'formik';
import { Input } from '../Input';
import { PhoneInput } from '../PhoneInput';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { formatCEP, formatCNPJ, formatCPF } from '../../utils';
import useSWR from 'swr';
import { CreateCustomerSchema } from '@next-pontos/validations';

interface CreditCardModalProps {
    open: boolean
    onClose: () => void
    onSubmit: (customerId: string) => void
    customerId?: number
}

export const CreateCustomerModal = (props: CreditCardModalProps) => {
    const [customer, getCustomer] = useAxios({
        url: `/customer/${props.customerId}`
    }, {
        manual: true
    })

    const [createCustomerControls, createCustomer] = useAxios({
        url: '/customer',
        method: 'POST',
    }, {
        manual: true
    })

    const [updateCustomerControls, updateCustomer] = useAxios({
        url: `/customer/${props.customerId}`,
        method: 'PUT',
    }, {
        manual: true
    })

    const createCustomerFormik = useFormik({
        initialValues: {
            name: '',
            phone_number: '',
            address_cep: '',
            address_street: '',
            address_number: '',
            address_neighborhood: '',
            address_complement: '',
            address_city: '',
            address_state: '',
            email: '',
            document_number: '',
            is_business: '',
            password: ''
        },
        validationSchema: CreateCustomerSchema,
        onSubmit: async (values) => {
            if (props.customerId) {
                const { data: customer } = await updateCustomer({
                    data: values
                })
                
                props.onSubmit(customer.id)
            } else {
                const {data: customer} = await createCustomer({
                    data: values
                })

                props.onSubmit(customer.id)
            }

            props.onClose()
        }
    })

    const cleanCEP = createCustomerFormik.values.address_cep.replace(/\D/ig, '')

    const cepSearch = useSWR(`https://viacep.com.br/ws/${cleanCEP}/json/`, (...args) => { 
        if (cleanCEP.length === 8) {
            return fetch(...args).then(res => res.json())
        }

        return
    })

    useEffect(() => {
        if (cepSearch.data && !cepSearch.isLoading && !cepSearch.error) {
            createCustomerFormik.setFieldValue('address_street', cepSearch.data.logradouro || '')
            createCustomerFormik.setFieldValue('address_city', cepSearch.data.localidade || '')
            createCustomerFormik.setFieldValue('address_state', cepSearch.data.uf || '')
            createCustomerFormik.setFieldValue('address_neighborhood', cepSearch.data.bairro || '')
        }
    }, [cepSearch.data])

    if (!customer.loading && !customer.data && !customer.error && props.customerId) {
        getCustomer().then(({data}) => {
            createCustomerFormik.resetForm({
                values: {
                    name: data.name,
                    is_business: data.is_business,
                    document_number: data.is_business ? formatCNPJ(data.document_number) : formatCPF(data.document_number),
                    email: data.email,
                    phone_number: data.phone_number,
                    address_cep: formatCEP(data.address_cep),
                    address_street: data.address_street,
                    address_city: data.address_city,
                    address_number: data.address_number,
                    address_complement: data.address_complement,
                    address_neighborhood: data.address_neighborhood,
                    address_state: data.address_state,
                    password: ''
                }
            })
        })
    }
    
    return (
        <Modal
            title={props.customerId ? "Atualizar Cliente" : "Cadastrar Cliente"}
            open={props.open}
            onClose={!createCustomerControls.loading ? props.onClose : () => {}}
            bodyCustomCss={{
                width: 500,
                maxHeight: '65vh',
                overflowY: "auto"
            }}
            confirmLabel={props.customerId ? "Atualizar" : "Cadastrar"}
            onConfirm={createCustomerControls.loading ? undefined : createCustomerFormik.handleSubmit}
        >
            <Grid container spacing={2}>
                {createCustomerControls.loading ? (
                    <Grid item xs={12} textAlign="center">
                        <Loader />
                    </Grid>
                ) : (
                <Fragment>
                    <Grid item xs={12}>
                        <Input
                            label="Nome"
                            name="name"
                            onChange={createCustomerFormik.handleChange}
                            value={createCustomerFormik.values.name}
                            helperText={createCustomerFormik.touched.name && createCustomerFormik.errors.name}
                            error={!!createCustomerFormik.touched.name && !!createCustomerFormik.errors.name}
                            onBlur={createCustomerFormik.handleBlur}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroup
                            value={createCustomerFormik.values.is_business}
                            onChange={event => {
                                const is_business = event.target.value === "true"

                                createCustomerFormik.setFieldValue('is_business', is_business, true)

                                createCustomerFormik.setFieldValue(
                                    'document_number', 
                                    is_business 
                                        ? formatCNPJ(createCustomerFormik.values.document_number) 
                                        : formatCPF(createCustomerFormik.values.document_number)
                                )
                            }}
                        >
                            <FormControlLabel value={false} control={<Radio />} label="Pessoa Física" />
                            <FormControlLabel value={true} control={<Radio />} label="Pessoa Jurídica" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={5}>
                        <Input 
                            label={createCustomerFormik.values.is_business ? "CNPJ" : "CPF"}
                            name="document_number"
                            onChange={({target}) => {
                                createCustomerFormik.setFieldValue(
                                    'document_number', 
                                    createCustomerFormik.values.is_business 
                                        ? formatCNPJ(target.value) 
                                        : formatCPF(target.value)
                                )
                            }}
                            onBlur={createCustomerFormik.handleBlur}
                            value={createCustomerFormik.values.document_number}
                            helperText={createCustomerFormik.touched.document_number && createCustomerFormik.errors.document_number}
                            error={!!createCustomerFormik.touched.document_number && !!createCustomerFormik.errors.document_number}
                            disabled={createCustomerFormik.values.is_business === ""}
                        />
                    </Grid>
                    <Grid item xs={7}>
                        <Input
                            label="E-mail"
                            name="email"
                            onChange={createCustomerFormik.handleChange}
                            value={createCustomerFormik.values.email}
                            helperText={createCustomerFormik.touched.email && createCustomerFormik.errors.email}
                            error={!!createCustomerFormik.touched.email && !!createCustomerFormik.errors.email}
                            onBlur={createCustomerFormik.handleBlur}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <PhoneInput
                            label="Telefone"
                            inputProps={{
                                name: 'phone_number'
                            }}
                            onChange={value => {
                                createCustomerFormik.setFieldValue('phone_number', value, true)
                            }}
                            value={createCustomerFormik.values.phone_number}
                            onBlur={createCustomerFormik.handleBlur}
                            error={!!createCustomerFormik.touched.phone_number && !!createCustomerFormik.errors.phone_number}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Input 
                            label="CEP"
                            name="address_cep"
                            onChange={(event) => {
                                createCustomerFormik.setFieldValue(
                                    'address_cep', 
                                    event.target.value.replace(/\D/g,'').replace(/(\d{5})(\d)/,'$1-$2')
                                )
                            }}
                            value={createCustomerFormik.values.address_cep}
                            helperText={createCustomerFormik.touched.address_cep && createCustomerFormik.errors.address_cep}
                            onBlur={createCustomerFormik.handleBlur}
                            error={!!createCustomerFormik.touched.address_cep && !!createCustomerFormik.errors.address_cep}
                        />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Input 
                            label="Logradouro"
                            name="address_street"
                            onChange={createCustomerFormik.handleChange}
                            value={createCustomerFormik.values.address_street}
                            helperText={createCustomerFormik.touched.address_street && createCustomerFormik.errors.address_street}
                            onBlur={createCustomerFormik.handleBlur}
                            error={!!createCustomerFormik.touched.address_street && !!createCustomerFormik.errors.address_street}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Input 
                            label="Número"
                            name="address_number"
                            onChange={createCustomerFormik.handleChange}
                            value={createCustomerFormik.values.address_number}
                            helperText={createCustomerFormik.touched.address_number && createCustomerFormik.errors.address_number}
                            onBlur={createCustomerFormik.handleBlur}
                            error={!!createCustomerFormik.touched.address_number && !!createCustomerFormik.errors.address_number}
                        />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <Input 
                            label="Complemento"
                            name="address_complement"
                            onChange={createCustomerFormik.handleChange}
                            value={createCustomerFormik.values.address_complement}
                            helperText={createCustomerFormik.touched.address_complement && createCustomerFormik.errors.address_complement}
                            onBlur={createCustomerFormik.handleBlur}
                            error={!!createCustomerFormik.touched.address_complement && !!createCustomerFormik.errors.address_complement}
                        />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <Input 
                            label="Bairro"
                            name="address_neighborhood"
                            onChange={createCustomerFormik.handleChange}
                            value={createCustomerFormik.values.address_neighborhood}
                            helperText={createCustomerFormik.touched.address_neighborhood && createCustomerFormik.errors.address_neighborhood}
                            onBlur={createCustomerFormik.handleBlur}
                            error={!!createCustomerFormik.touched.address_neighborhood && !!createCustomerFormik.errors.address_neighborhood}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Input 
                            label="Cidade"
                            name="address_city"
                            onChange={createCustomerFormik.handleChange}
                            value={createCustomerFormik.values.address_city}
                            helperText={createCustomerFormik.touched.address_city && createCustomerFormik.errors.address_city}
                            onBlur={createCustomerFormik.handleBlur}
                            error={!!createCustomerFormik.touched.address_city && !!createCustomerFormik.errors.address_city}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Input 
                            label="Estado"
                            name="address_state"
                            onChange={createCustomerFormik.handleChange}
                            value={createCustomerFormik.values.address_state}
                            helperText={createCustomerFormik.touched.address_state && createCustomerFormik.errors.address_state}
                            onBlur={createCustomerFormik.handleBlur}
                            error={!!createCustomerFormik.touched.address_state && !!createCustomerFormik.errors.address_state}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input 
                            label="Senha"
                            type='password'
                            name="password"
                            onChange={createCustomerFormik.handleChange}
                            value={createCustomerFormik.values.password}
                            helperText={createCustomerFormik.touched.password && createCustomerFormik.errors.password}
                            onBlur={createCustomerFormik.handleBlur}
                            error={!!createCustomerFormik.touched.password && !!createCustomerFormik.errors.password}
                        />
                    </Grid>
                </Fragment>)}
            </Grid>
        </Modal>
    )
}