import { TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { Input } from '../Input';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
  }

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
      const { onChange, ...other } = props;
  
      return (
        <NumericFormat
          {...other}
          getInputRef={ref}
          onValueChange={(values) => {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }}
          thousandSeparator="."
          decimalScale={0}
          decimalSeparator=","
          valueIsNumericString
        />
      );
    },
  );  


export const NumericInput = (props: TextFieldProps & { onChange: ({ target: { value, name } }: { target: { value: string, name: string } }) => void }) => {
    return (
        <Input 
            {...props} 
            InputProps={{
                inputComponent: NumericFormatCustom as any,
            }}      
        />
    )
}
  
  