import { Grid, Layout, Loader, Table, Typography } from "../../../components";
import { Paper } from "../../../components";
import { Fragment} from 'react'
import { Chip, Link, Tooltip } from "@mui/material";
import { formatDate, formatMoney, getBidStatusColor, getBidStatusLabel, getPaymentStatusColor, getPaymentStatusLabel } from "../../../utils";
import { useParams } from "react-router-dom";
import moment from "moment";
import { COMPANY_OPTIONS } from "../../../constants";
import { useAxios } from "../../../hooks";

export function MyBid () { 
    const params = useParams()
    
    const [bid] = useAxios(`/bid/${params.id}`)
    
    return (
        <Layout>
            <Grid container justifyContent="center" gap={4} minHeight={"calc(100vh - 64px)"} alignItems="flex-start" paddingTop={5}>
                {bid.loading ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) : (<Fragment>
                <Grid item xs={12} sm={4}>
                    <Paper elevation={1} >
                        <Grid container padding={2} spacing={2}>
                            <Grid item xs={12} marginBottom={2}>
                                <Typography textAlign={"center"} variant="h5">Detalhes da Oferta</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            ID:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="end">
                                    <Link href={`/minhas-ofertas/${bid?.data.id}`}>
                                        # {bid?.data?.id}
                                    </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={3}>
                                        <Typography fontWeight="bold">
                                            Data:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography textAlign="end">
                                            {moment(bid?.data?.created_at).format('DD/MM/YYYY HH:mm:ss')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={3}>
                                        <Typography fontWeight="bold">
                                            Status:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} textAlign="end">
                                        <Chip color={getBidStatusColor(bid?.data.status)} label={getBidStatusLabel(bid?.data.status)} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={3}>
                                        <Typography fontWeight="bold">
                                            Companhia:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography textAlign="end">
                                            {COMPANY_OPTIONS.find(item => item.value === bid?.data?.company)?.label}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={3}>
                                        <Typography fontWeight="bold">
                                            Quantidade:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} display="flex" flexDirection="row" justifyContent="flex-end">
                                        <Typography textAlign="end">
                                            {Number(bid?.data?.amount).toLocaleString('pt-BR')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={4}>
                                        <Typography fontWeight="bold">
                                            CPFs Disponíveis:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8} display="flex" flexDirection="row" justifyContent="flex-end">
                                        <Typography textAlign="end">
                                            {bid?.data?.pax === null ? '∞' : bid?.data?.pax}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={4}>
                                        <Typography fontWeight="bold">
                                            Valor do Milheiro:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography textAlign="end">
                                            {formatMoney(bid?.data?.price_per_k)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Paper elevation={1} style={{ marginTop: 30 }}>
                        <Grid container padding={2} spacing={2}>
                            <Grid item xs={12} marginBottom={2}>
                                <Typography textAlign={"center"} variant="h5">Pagamentos</Typography>
                            </Grid>
                            <Grid item xs={12}>
                            <Table
                                columns={[
                                    { field: 'id', headerName: 'ID', width: 50 },
                                    { field: 'due_date', headerName: 'Vencimento', flex: 1, valueFormatter: ({value}) => {
                                        return formatDate(value)
                                    }},
                                    { field: 'amount', headerName: 'Valor', flex: 1, valueFormatter: ({value}) => {
                                        return formatMoney(value)
                                    }},
                                    {
                                        field: 'status', headerName: 'Status', flex: 1, 
                                        valueGetter: ({value}) => {
                                            return getPaymentStatusLabel(value)
                                        },
                                        renderCell: ({ formattedValue, row }) => {
                                            return (
                                                <Chip color={getPaymentStatusColor(row.status)} label={formattedValue} />
                                            )
                                        }
                                    }
                                ]}
                                rows={bid?.data?.orders?.map((item: { payment: { id: string }}) => item.payment) || []}
                            />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                </Fragment>)}
            </Grid>
        </Layout>
    )
}