import { Alert, DateRangePicker, Grid, Layout, Loader, Paper, Typography } from "../../components";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid, PieChart, Pie, Cell, BarChart, Bar } from 'recharts';
import { Palette, PaletteColor, useTheme } from "@mui/material";
import moment from "moment";
import { capitalizeFirstLetter, formatMoney, getBidStatusColor, getBidStatusLabel } from "../../utils";
import { COMPANY_OPTIONS } from "../../constants";
import { Fragment } from "react";
import { Link } from "@mui/material";
import currency from "currency.js";
import {useAxios} from "../../hooks";
import { Orders } from "./Orders";
import { MilesNegotiated } from "./MilesNegotiated";

export function Dashboard () { 
    const theme = useTheme()
    
    const [dashboard] = useAxios('/dashboard')
    const [milesInStockPerCompany] = useAxios('/dashboard/miles-in-stock-per-company')
    
    return (
        <Layout>
            <Grid container justifyContent="center" height={"calc(100vh - 64px)"} padding={3} spacing={2} overflow="scroll">
                {dashboard.loading ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) : (<Fragment>
                <Grid item xs={12} sm={6} height={"60%"}>
                    <Paper elevation={1} sx={{
                        height: '100%',
                        padding: 2
                    }}>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            Lucros Mensais
                        </Typography>
                        <div style={{
                            height: '100%'
                        }}>
                        <ResponsiveContainer
                            height={'90%'}
                        >
                            {!dashboard.loading ? (<LineChart
                            data={dashboard.data?.monthly_profit || []}
                            margin={{
                                top: 16,
                                right: 16,
                                bottom: 0,
                                left: 16,
                            }}
                            >

                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="month"
                                includeHidden
                                stroke={theme.palette.text.secondary}
                                style={theme.typography.body2}
                                tickFormatter={tick => {
                                    return capitalizeFirstLetter(moment(`${tick}/01`).format('MMM/YYYY'))
                                }}
                                tickLine
                                tickCount={12}
                            >
                            </XAxis>
                            <YAxis
                                stroke={theme.palette.text.secondary}
                                style={theme.typography.body2}
                                tickFormatter={tick => {
                                    return formatMoney(tick)
                                }}
                                tick
                            >
                            </YAxis>
                            <Line
                                isAnimationActive={false}
                                type="monotone"
                                dataKey="amount"
                                stroke={theme.palette.primary.main}
                            />
                            <Tooltip 
                                formatter={(value: number) => {
                                    return [formatMoney(value)]
                                }}
                                labelFormatter={label => {
                                    return capitalizeFirstLetter(moment(`${label}/01`).format('MMMM/YYYY'))
                                }}
                                labelStyle={{
                                    fontWeight: 'bold'
                                }}
                            />
                            <Legend 
                                formatter={() => {
                                    return "Lucro"
                                }}
                                fontWeight="bold"
                            /> 
                            </LineChart>) : ( <div />)}
                        </ResponsiveContainer>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} height={"60%"}>
                <Paper elevation={1} sx={{
                        height: '100%',
                        padding: 2
                    }}>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            Milhas em Estoque
                        </Typography>
                        <div style={{
                            height: '100%'
                        }}>
                        <ResponsiveContainer
                            height={'90%'}
                        >
                            {!milesInStockPerCompany.loading ? (
                                <PieChart
                                    margin={{
                                        top: 16,
                                        right: 16,
                                        left: 16,
                                        bottom: 16,
                                    }}
                                >
                                <Pie 
                                    data={milesInStockPerCompany?.data || []} 
                                    dataKey="amount" 
                                    label={(payload) => {
                                        return `${COMPANY_OPTIONS.find(item => item.value === payload.company)?.label}: ${Number(payload.amount).toLocaleString('pt-BR')} (${currency(payload.percent).multiply(100).value}%)`
                                    }}
                                >
                                {
                                    milesInStockPerCompany.data?.map((item: { company: string }) => {
                                        return (
                                            <Cell 
                                                key={item.company} 
                                                fill={COMPANY_OPTIONS.find(company => company.value === item.company)?.color}
                                            />
                                        )
                                    })
                                }
                                </Pie>
                                <Tooltip 
                                    formatter={(value: number, index) => {
                                        return [
                                            `${COMPANY_OPTIONS.find(company => company.value === milesInStockPerCompany.data[index].company)?.label}: ${Number(value).toLocaleString('pt-BR')}`
                                        ]
                                    }}
                                    labelFormatter={label => {
                                        return COMPANY_OPTIONS.find(company => company.value === label.company)?.label
                                    }}
                                    labelStyle={{
                                        fontWeight: 'bold'
                                    }}
                                />
                                <Legend 
                                    formatter={value => {
                                        return COMPANY_OPTIONS.find(company => company.value === milesInStockPerCompany.data[value].company)?.label
                                    }}
                                    fontWeight="bold"
                                /> 
                              </PieChart>
                            ) : ( <div />)}
                        </ResponsiveContainer>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} height={"60%"}>
                    <Orders />
                </Grid>

                <Grid item xs={12} sm={6} height={"60%"}>
                    <Paper elevation={1} sx={{
                        height: '100%',
                        padding: 2,
                    }}>
                        <MilesNegotiated />
                    </Paper>
                </Grid>
                </Fragment>)}
            </Grid>
        </Layout>
    )
}