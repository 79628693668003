import { enqueueSnackbar } from 'notistack';
import Axios, { AxiosError, AxiosHeaders } from 'axios' 

export const API = Axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`
})

API.interceptors.request.use((config) => {
    const token = window.localStorage.getItem('@next-pontos/token')
    const customerToken = window.localStorage.getItem('@next-pontos/customer-token')

    if (token) {
        (config.headers as AxiosHeaders).set('Authorization', `Bearer ${token}`)
    }

    if (
        ['/customer/external-dash', '/customer/me'].includes(config.url as string)
    ) {
        (config.headers as AxiosHeaders).set('Authorization', `Bearer ${customerToken}`)
    }

    return config 
})

API.interceptors.response.use(success => success, (error: AxiosError<{ message?: string }>) => {
    if ([403, 409].includes(error.response?.status as number)) {
        enqueueSnackbar(error.response?.data.message || 'Erro interno! Entre em contato com o administrador do sistema', {
            variant: 'error'
        })
        
        return Promise.resolve(error)
    }

    if (error.response?.status === 401) {
        if (window.location.pathname !== '/painel-cliente') {
            window?.localStorage?.removeItem('@next-pontos/token')
        } else {
            window?.localStorage?.removeItem('@next-pontos/customer-token')
        }
        
        if (
            window.location.pathname !== '/' 
            && window.location.pathname !== '/captacao' 
            && window.location.pathname !== '/emitir' 
            && window.location.pathname !== '/cadastro' 
            && window.location.pathname !== '/esqueci-minha-senha' 
            && window.location.pathname !== '/nova-senha'
            && window.location.pathname !== '/painel-cliente'
        ) {
            window.location.href = '/'
        }
    }
    
    return Promise.reject(error)
})

export default API