import { Grid, Menu, Layout, Paper, Table, ButtonGroup, Loader, Typography, Alert, MultipleAutocomplete, MultipleAutocompleteOption } from "../../components";
import useSWR from 'swr'
import API from "../../API";
import moment from "moment";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, Chip, Link, Tab, Tabs, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatDate, formatMoney, getPaymentStatusColor, getPaymentStatusLabel } from "../../utils";
import { useAxios } from "../../hooks";
import { Fragment, useMemo } from "react";
import { Bar, BarChart, CartesianGrid, Legend, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { groupBy, sum, sumBy } from "lodash";
import { Line } from "recharts";
import { GridFilterInputValueProps, GridFilterItem, GridFilterOperator } from "@mui/x-data-grid";
import { useTabs } from "@mui/base";
import {Payments} from './Payments'
import { Fees } from "./Fees";

const today = moment().toDate()
const oneMonth = moment().add(5, 'days').toDate()

const diff = moment(oneMonth).diff(today, 'days')

const month: Array<Date> = []

for (let day = 0; day < diff; day++) {
    month.push(moment(today).add(day, 'day').toDate())
}

const statusOptions = [
    { value: 'AWAITING_ORDER_CONFIRMATION', label: 'Aguardando Confirmação' },
    { value: 'CANCELLED', label: 'Cancelado' },
    { value: 'PENDING', label: 'Pendente' },
    { value: 'PAID', label: 'Pago' },
    { value: 'LATE', label: 'Atrasado' },
]

function StatusSelector(props: GridFilterInputValueProps) {
    const { item, applyValue } = props;
  
    return (
        <MultipleAutocomplete
        variant="standard"
            label="Status"
            options={statusOptions}
            value={statusOptions.filter(option => item?.value?.includes(option.value))}
            onChange={(value) => {
                applyValue({
                    ...item,
                    value: (value as unknown as MultipleAutocompleteOption[]).map(item => item.value)
                })
            }}
        />
    );
  }
  
const statusSelectorOperator: GridFilterOperator[] = [
{
    label: 'é igual a',
    value: 'in',
    getValueAsString: (values) => {
        return values.map((item: string) => statusOptions.find(({value}) => value === item)?.label).join(', ')
    },
    getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!Array.isArray(filterItem.value) || filterItem.value.length === 0 || !filterItem.value) {
            return null;
        }

        return ({ value }) => {
            return filterItem.value.includes(value)
        };
    },
    InputComponent: StatusSelector,
}]; 

function a11yProps(index: number) {
    return {
      id: `banking-tab-${index}`,
      'aria-controls': `banking-tabpanel-${index}`,
    };
  }

  
export function Banking () {
    const tabs = useTabs({
        defaultValue: 0
    })

    return ( 
        <Layout>
            <Grid container justifyContent="center" /*minHeight={"calc(100vh - 64px)"}*/ padding={3} gap={3}>
                <Box sx={{ width: '100%' }}>
                    <Paper>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tabs.contextValue.value} onChange={tabs.contextValue.onSelected} >
                                <Tab label="Pagamentos" {...a11yProps(0)} />
                                <Tab label="Taxas" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                    </Paper>
                    {tabs.contextValue.value === 0 && (
                        <Payments />
                    )}
                    {tabs.contextValue.value === 1 && (
                        <Fees />
                    )}
                </Box>
            </Grid>
        </Layout>
    )
}