import { Button, DatePicker, Grid, Input, Layout, PhoneInput, Switch, Table, Typography } from "../../../components";
import { Paper, Loader } from "../../../components";
import { useFormik } from "formik";
import { UpdateAccountValidationSchema } from "@next-pontos/validations";
import { useAPI } from "../../../hooks";
import {Fragment, useEffect} from 'react'
import useSWR from 'swr'
import { Chip, Tooltip } from "@mui/material";
import { formatCPF, getBidStatusColor, getBidStatusLabel } from "../../../utils";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../../API";
import { COMPANY_OPTIONS } from "../../../constants";
import currency from "currency.js";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useAxios} from "../../../hooks";
import moment from "moment";

export function Account () { 
    const params = useParams()
    const navigate = useNavigate()

    const [account] = useAxios(`/account/${params.id}`)

    const [updatedAccount, updateAccount] = useAxios({
        url: `/account/${params.id}`,
        method: 'PUT'
    }, {
        manual: true
    })
    
    const formik = useFormik({
        initialValues: {
            holder_name: '',
            holder_email: '',
            holder_phone_number: '',
            holder_cpf: '',
            login: '',
            password: '',
            secondary_password: '',
            is_membership_active: false,
            holder_birthday: '',
            membership_number: '',
            
        },
        onSubmit: async (values) => {
            await updateAccount({
                data: values
            })
            
            window.location.reload()
        },
        initialStatus: 'draft',
        validateOnMount: true,
        validationSchema: UpdateAccountValidationSchema
    })

    useEffect(() => {
        if (account.data) {
            formik.resetForm({
                values: {
                    holder_name: account?.data?.holder_name || '',
                    holder_email: account?.data?.holder_email || '',
                    holder_phone_number: account?.data?.holder_phone_number || '',
                    holder_cpf: formatCPF(account?.data?.holder_cpf || ''),
                    login: account?.data?.login || '',
                    password: '',
                    secondary_password: '',
                    is_membership_active: account?.data?.is_membership_active || false,
                    holder_birthday: account?.data?.holder_birthday || '',
                    membership_number: account?.data?.membership_number || '',
                },
                status: 'initiated',
                touched: {}
            })
        }
    }, [account.data])

    return (
        <Layout>
            <Grid container justifyContent="center" gap={4} minHeight={"calc(100vh - 64px)"} alignItems="flex-start" paddingTop={5}>
                {(account.loading || updatedAccount.loading || formik.status === 'draft') ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) : (<Fragment>
                    <Grid item xs={12} sm={4}>
                    <Paper elevation={1}>
                    <form onSubmit={formik.handleSubmit}>
                    <Grid container padding={2} spacing={2} justifyContent="center">
                        <Grid item xs={12} marginBottom={2}>
                            <Typography textAlign={"center"} variant="h5">Editar Conta</Typography>
                        </Grid>
                            <Grid item xs={12} sm={6}> 
                                <Input 
                                    label="Companhia"
                                    disabled
                                    select
                                    options={COMPANY_OPTIONS}
                                    value={COMPANY_OPTIONS.find(item => item.value === account?.data?.company)?.value}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} display="flex" alignItems="center" justifyContent="center"> 
                                <Switch
                                    label="Possui clube ativo?"
                                    checked={formik.values.is_membership_active}
                                    onChange={() => {
                                        formik.setFieldValue('is_membership_active', !formik.values.is_membership_active, false)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}> 
                                <Input 
                                    label="Nome do Titular"
                                    name="holder_name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.holder_name}
                                    helperText={formik.touched.holder_name && formik.errors.holder_name}
                                    error={!!formik.touched.holder_name && !!formik.errors.holder_name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}> 
                                <Input 
                                    label="E-mail do Titular"
                                    name="holder_email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.holder_email}
                                    helperText={formik.touched.holder_email && formik.errors.holder_email}
                                    error={!!formik.touched.holder_email && !!formik.errors.holder_email}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DatePicker
                                    label="Data de Nascimento do Titular"
                                    value={formik.values.holder_birthday ? moment(formik.values.holder_birthday) : null}
                                    onChange={(value) => {
                                        formik.setFieldValue('holder_birthday', value?.toDate(), true)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}> 
                                <Tooltip
                                    title="Preencha este campo com o número de fidelidade da companhia. Caso não haja preencha com o CPF."
                                >
                                    <Input 
                                        label="Número de Fidelidade"
                                        name="membership_number"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.membership_number}
                                        helperText={formik.touched.membership_number && formik.errors.membership_number}
                                        error={!!formik.touched.membership_number && !!formik.errors.membership_number}
                                    />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} sm={6}> 
                                <Input 
                                    label="CPF do Titular"
                                    name="holder_cpf"
                                    onChange={({target}) => {
                                        formik.setFieldValue('holder_cpf', formatCPF(target.value))
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.holder_cpf}
                                    helperText={formik.touched.holder_cpf && formik.errors.holder_cpf}
                                    error={!!formik.touched.holder_cpf && !!formik.errors.holder_cpf}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}> 
                                <PhoneInput
                                    label="Telefone do Titular"
                                    inputProps={{
                                        name: 'holder_phone_number'
                                    }}
                                    onChange={value => {
                                        formik.setFieldValue('holder_phone_number', value, true)
                                    }}
                                    value={formik.values.holder_phone_number}
                                    onBlur={formik.handleBlur}
                                    error={!!formik.touched.holder_phone_number && !!formik.errors.holder_phone_number}
                                />
                            </Grid>
                            <Grid item xs={12}> 
                                <Input 
                                    label="Login"
                                    name="login"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.login}
                                    helperText={formik.touched.login && formik.errors.login}
                                    error={!!formik.touched.login && !!formik.errors.login}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}> 
                                <Input 
                                    label="Senha"
                                    name="password"
                                    type="password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                    helperText={formik.touched.password && formik.errors.password}
                                    error={!!formik.touched.password && !!formik.errors.password}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}> 
                                <Tooltip title="Alguns programas de fidelidade possuem uma senha secundária. Caso não possua, não é necessário preencher este campo.">
                                <Input 
                                    label="Contrassenha"
                                    name="secondary_password"
                                    type="password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.secondary_password}
                                    helperText={formik.touched.secondary_password && formik.errors.secondary_password}
                                    error={!!formik.touched.secondary_password && !!formik.errors.secondary_password}
                                />
                                </Tooltip>
                            </Grid>
                        <Grid item marginTop={2}>
                            <Button disabled={!formik.isValid} type="submit">Salvar</Button>
                        </Grid>
                    </Grid>
                    </form>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Paper elevation={1} >
                        <Grid container padding={2} spacing={2}>
                            <Grid item xs={12} marginBottom={2}>
                                <Typography textAlign={"center"} variant="h5">Ofertas</Typography>
                            </Grid>
                            <Grid item xs={12}>
                            <Table 
                                columns={[
                                    { field: 'id', headerName: 'ID', width: 50 },
                                    { field: 'amount', headerName: 'Quantidade', flex: 1, valueFormatter: ({value}) => {
                                        return Number(value).toLocaleString('pt-BR')
                                    }},
                                    { field: 'price_per_k', headerName: 'R$/K', flex: 1, valueFormatter: ({value}) => {
                                        return currency(value).format({
                                            symbol: 'R$ ',
                                            decimal: ',',
                                            separator: '.'
                                        })
                                    }},
                                    {
                                        field: 'status', headerName: 'Status', flex: 1, 
                                        valueGetter: ({value}) => {
                                            return getBidStatusLabel(value)
                                        },
                                        renderCell: ({ formattedValue, row }) => {
                                            return (
                                                <Chip color={getBidStatusColor(row.status)} label={formattedValue} />
                                            )
                                        }
                                    },
                                    {
                                        field: 'actions', headerName: '', sortable: false, filterable: false, disableColumnMenu: true, renderCell: ({row}) => {
                                            return (
                                                <Button variant="outlined" onClick={() => {
                                                    navigate(`/minhas-ofertas/${row.id}`)
                                                }}>
                                                    <VisibilityIcon />
                                                </Button>
                                            )
                                        }
                                    }
                                ]}
                                rows={account?.data?.bids || []}
                            />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                </Fragment>
                )}
            </Grid>
        </Layout>
    )
}