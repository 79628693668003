import { Button, Grid, Layout, Paper, Table, Loader, Modal } from "../../components";
import moment from "moment";
import { useAxios, useToggle } from '../../hooks'
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { IconButton, Link } from "@mui/material";
import { GridActionsCellItem, GridAddIcon, GridEventListener, GridRowEditStopReasons, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridRowsProp, GridToolbarContainer } from "@mui/x-data-grid";
import {v4} from 'uuid'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import AuthContext from "../../context/auth";
import { enqueueSnackbar } from "notistack";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { APP_DOMAIN } from "@next-pontos/settings";
import { isAxiosError } from "axios";
import QRCode from "react-qr-code";
import * as d3 from "d3";
import { saveAs } from "file-saver";
import { getSVGString } from "../../utils";

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
  }

function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props;
    const { me } = useContext(AuthContext)
  
    const handleClick = () => {
      const id = v4();
      setRows((oldRows) => [...oldRows, { id, name: '', voucher: '', created_at: new Date(), creator: { id: me?.id, name: me?.name}, isNew: true }]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
      }));
    };
  
    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<GridAddIcon />} onClick={handleClick}>
          Adicionar
        </Button>
      </GridToolbarContainer>
    );
}

type ReferralVoucher = { 
    id: number, name: string, created_at: Date, voucher: string, is_active: boolean
}

const width = 300;
const height = 300;

export function Coupons () {
    const [isDeleteModalVisible, isDeleteModalVisibleControls] = useToggle(false)
    const [isCuponQRCodeModalVisible, isCuponQRCodeModalVisibleControls] = useToggle(false)
    const [qrCodeLink, setQrCodeLink] = useState('')
    const [referralVouchers, referralVouchersControls] = useAxios(`/referral-voucher`)
    const [rows, setRows] = useState<Array<ReferralVoucher & { isNew: boolean }>>([]);
    const [, postReferralVouchers] = useAxios(``, { manual: true })
    const [deleteRowId, setDeleteRowId] = useState<number | string | undefined>()
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    let myCanvas = React.createRef<HTMLCanvasElement>();

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
      if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
      }
    };
  
    const handleEditClick = (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };
  
    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };
  
    const handleDeleteClick = (id: GridRowId) => () => {
        isDeleteModalVisibleControls.setTrue()

        setDeleteRowId(id)
    };
  
    const handleCancelClick = (id: GridRowId) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
  
      const editedRow = rows.find((row) => row.id === id);
      if (editedRow!.isNew) {
        setRows(rows.filter((row) => row.id !== id));
      }
    };
  
    const processRowUpdate = (newRow: GridRowModel<ReferralVoucher & {isNew: boolean}>) => {
        const updatedRow = { ...newRow, isNew: false };

        const voucher = newRow.voucher.replace(' ', '').trim()

        if (!newRow?.name || !voucher) {
            enqueueSnackbar('Preencha todos os campos!', { variant: 'warning' })
        } else {
            if (newRow.isNew) {
                postReferralVouchers({
                    url: '/referral-voucher',
                    method: 'POST',
                    data: {
                        name: newRow.name,
                        voucher,
                        is_active: newRow.is_active || false
                    }
                }).then(() => { 
                    enqueueSnackbar('Cupom adicionado com sucesso!', {variant: 'success'}) 
                    referralVouchersControls()
                })
            } else {
                postReferralVouchers({
                    url: `/referral-voucher/${newRow.id}`,
                    method: 'PUT',
                    data: {
                        name: newRow.name,
                        voucher,
                        is_active: newRow.is_active || false
                    }
                }).then(() => { 
                    enqueueSnackbar('Cupom atualizado com sucesso!', {variant: 'success'}) 
                    referralVouchersControls()
                })
            }

            setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
            return updatedRow;
        }
    };
  
    const processRowDelete = useCallback(() => {
        postReferralVouchers({
            url: `/referral-voucher/${deleteRowId}`,
            method: 'DELETE',
        }).then((result) => { 
            if (!isAxiosError(result)) {
                isDeleteModalVisibleControls.setFalse()
                enqueueSnackbar('Cupom excluído com sucesso!', {variant: 'success'}) 
                referralVouchersControls()
            }
        })
    }, [deleteRowId, isDeleteModalVisibleControls, postReferralVouchers, referralVouchersControls])

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
      setRowModesModel(newRowModesModel);
    };

    const onClickHandler = useCallback(() => {
        const svg = d3.select("svg.couponQrCode");
        const svgString = getSVGString(svg.node() as HTMLElement);
    
        let context = myCanvas?.current?.getContext("2d");
        
        if (!myCanvas.current || !context) {
            return enqueueSnackbar('Erro ao baixar a imagem', {variant: 'error'})
        }

        myCanvas.current.width = width;
        myCanvas.current.height = height;
        let imgsrc =
          "data:image/svg+xml;base64," +
          btoa(unescape(encodeURIComponent(svgString)));
    
        let image = new Image();

        image.onload = () => {
            if (!context || !myCanvas?.current) {
                return 
            }

            context?.clearRect(0, 0, width, height);
            context.fillStyle = "white";
            context?.fillRect(0, 0, width, height);
            context?.drawImage(image, 0, 0, width, height);

            myCanvas?.current?.toBlob((blob: any) => {
                let filesize = Math.round(blob.length / 1024) + "KB";
                saveAs(blob, "cupom.png");
            });
        };
    
        image.src = imgsrc;
      }, [myCanvas])

    useEffect(() => {
        setRows((referralVouchers.data as Array<ReferralVoucher & {isNew: boolean}>)?.map(item => ({ ...item, isNew: false })) || [])
    }, [referralVouchers.data])

    return (
        <Fragment>
            <Modal
                open={isDeleteModalVisible}
                title="Atenção!"
                onConfirm={processRowDelete}
                confirmLabel="Confirmar"
                bodyCustomCss={{
                    width: 500
                }}
                onClose={isDeleteModalVisibleControls.setFalse}
            >
                <div>
                    <span>Você tem certeza que deseja deletar esse voucher?</span>
                    <br />
                    <span>Esta ação é irreversível e não pode ser desfeita.</span>
                    <br />
                    <br />
                    <span style={{fontStyle: 'italic', fontSize: 13}}>
                        Obs.: Caso hajam usuários que tenham utilizado este cupom, ele não será excluído. Neste caso, desative-o.
                    </span>
                </div>
            </Modal>
            <Modal
                open={isCuponQRCodeModalVisible}
                onClose={isCuponQRCodeModalVisibleControls.setFalse}
                confirmLabel="Baixar QR Code"
                onConfirm={onClickHandler}
            >
                <div style={{display: 'flex', alignItems:'center', flexDirection: 'column'}}>
                    <canvas id="myCanvas" width="0" height="0" ref={myCanvas} style={{display: 'none'}}/>
                    <QRCode value={qrCodeLink} className="couponQrCode" />
                    <Link href={qrCodeLink}>
                        {qrCodeLink}
                    </Link>
                </div>
            </Modal>
            <Layout>
                <Grid container justifyContent="center" padding={3} spacing={2}>
                    {referralVouchers.loading ? (
                        <div style={{
                            display: 'flex',
                            flex: '1',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Loader />
                        </div>
                    ) : (
                    <Fragment>
                    <Grid item xs={12} sm={10}>
                        <Paper elevation={1}>
                        <Table 
                            columns={[
                                { field: 'created_at', headerName: 'Criado em', width: 200, type: 'dateTime', valueFormatter: ({value}) => {
                                    return moment(value).format('DD/MM/YYYY HH:mm:ss')
                                } },
                                { field: 'creator', headerName: 'Criado por', flex: 1, type: 'string', renderCell: ({row, value}) => {
                                    return (
                                        <Link href={`/usuarios/${row.creator?.id}`}>
                                            {value.name}
                                        </Link>
                                    )
                                }},
                                { field: 'name', headerName: 'Nome', flex: 1, type: 'string', editable: true, },
                                { field: 'voucher', headerName: 'Código', flex: 1, type: 'string', editable: true, renderCell: ({value}) => {
                                    return (
                                        <Fragment>
                                            <IconButton
                                                aria-label="Copiar Cupom"
                                                onClick={() => {
                                                    const url = `${APP_DOMAIN}/cadastro?invite_code=${value}`

                                                    isCuponQRCodeModalVisibleControls.setTrue()
                                                    navigator.clipboard.writeText(url)
                                                    setQrCodeLink(url)
                                                    enqueueSnackbar('Voucher copiado para área de transfência!', {variant: 'success'})
                                                }}
                                            >
                                                <ContentCopyIcon />
                                            </IconButton>
                                            {value}
                                        </Fragment>
                                    )
                                } },
                                { field: 'is_active', headerName: 'Ativo', flex: 1, type: 'boolean', editable: true },
                                {
                                    field: 'actions',
                                    type: 'actions',
                                    width: 100,
                                    cellClassName: 'actions',
                                    getActions: ({ id }) => {
                                    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                            
                                    if (isInEditMode) {
                                        return [
                                        <GridActionsCellItem
                                            icon={<SaveIcon />}
                                            label="Save"
                                            sx={{
                                            color: 'primary.main',
                                            }}
                                            onClick={handleSaveClick(id)}
                                        />,
                                        <GridActionsCellItem
                                            icon={<CancelIcon />}
                                            label="Cancel"
                                            className="textPrimary"
                                            onClick={handleCancelClick(id)}
                                            color="inherit"
                                        />,
                                        ];
                                    }
                            
                                    return [
                                        <GridActionsCellItem
                                        icon={<EditIcon />}
                                        label="Edit"
                                        className="textPrimary"
                                        onClick={handleEditClick(id)}
                                        color="inherit"
                                        />,
                                        <GridActionsCellItem
                                        icon={<DeleteIcon />}
                                        label="Delete"
                                        onClick={handleDeleteClick(id)}
                                        color="inherit"
                                        />,
                                    ];
                                    },
                                }
                            ]}
                            rows={(rows || [])}
                            editMode="row"
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={handleRowModesModelChange}
                            onRowEditStop={handleRowEditStop}
                            processRowUpdate={processRowUpdate}
                            slots={{
                                toolbar: EditToolbar
                            }}
                            slotProps={{
                                toolbar: { 
                                    setRows, 
                                    setRowModesModel
                                },
                            }}
                        />
                        </Paper>
                    </Grid>
                    </Fragment>)}
                </Grid>
            </Layout>
        </Fragment>
    )
}